import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import store from "../store"
import { FETCH_ORDER_LIST_FAILURE, FETCH_ORDER_LIST_REQUEST, FETCH_ORDER_LIST_SUCCESS, FETCH_RECEIVEDGIFTS_SUCCESS } from "./MyWalletTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';

export const fetchOrderListRequest=()=>{
    return {
        type:FETCH_ORDER_LIST_REQUEST
    }
}

export const fetchOrderListSuccess= data =>{
    return {
        type:FETCH_ORDER_LIST_SUCCESS,
        payload:data
    }
}

export const fetchOrderListFailure= error =>{
    return {
        type:FETCH_ORDER_LIST_FAILURE,
        payload:error
    }
}

export const fetchOrderList = (payload,countrySelected) => {
    const expPayload = {
        "experience_orders": {
            "currency": countrySelected
        }
    }
    return (dispatch)=>{
        dispatch({type: FETCH_ORDER_LIST_REQUEST})
            dispatch(fetchOrderListRequest);
            const eCardEMagOrdersResponse = axios.post(`${REACT_APP_baseUrl}/orders/orders_list`,
                                            payload,{
                                                headers:{
                                                    "Authorization":`Bearer ${store.getState().auth.authToken}`
                                                },
                                                params:{
                                                    
                                                }
                                            })
            
            const merchantOrdersResponse = 
                                            axios.post(`${REACT_APP_baseUrl}/merchant_product_orders/get_orders`,
                                            {
                                                "type": "OTHERS"
                                            }
                                            ,{
                                                headers:{
                                                    "Authorization":`Bearer ${store.getState().auth.authToken}`
                                                },
                                                params:{
                                                    
                                                }
                                            })
            const physicalGiftcardsOrdersResponse = 
                                            axios.post(`${REACT_APP_baseUrl}/merchant_product_orders/get_orders`,{
                                                "type": "PHYSICAL_GIFT_CARD"
                                                }
                                            ,{
                                                headers:{
                                                    "Authorization":`Bearer ${store.getState().auth.authToken}`
                                                },
                                                params:{
                                                    
                                                }
                                            })
            const offersOrdersResponse = axios.post(`${REACT_APP_baseUrl}/offer_orders/get_orders`,
                                        payload,{
                                            headers:{
                                                "Authorization":`Bearer ${store.getState().auth.authToken}`
                                            },
                                            params:{
                                                
                                            }
                                        })
            


            const experiencesOrdersResponse = axios.post(`${REACT_APP_baseUrl}/experience_orders/get_orders`,
            expPayload,{
                                            headers:{
                                                "Authorization":`Bearer ${store.getState().auth.authToken}`
                                            },
                                            params:{
                                            }
                                        })    
                                        const upcomingflightsResponse = axios.get(`${REACT_APP_baseUrl}/travel_shops/get_upcomming_flights`,
                                        {
                                            headers:{
                                                "Authorization":`Bearer ${store.getState().auth.authToken}`
                                            },
                                            params:{
                                                "order":"desc",
                                                "type":"future"
                                            }})  
                                            
                                        const pastflightsResponse = axios.get(`${REACT_APP_baseUrl}/travel_shops/get_upcomming_flights`,
                                        {
                                            headers:{
                                                "Authorization":`Bearer ${store.getState().auth.authToken}`
                                            },
                                            params:{
                                                "order":"desc",
                                                "type":"past"
                                            }})  
                                                                                    
            axios.get(`${REACT_APP_baseUrl}/giftcards/received_gifts`,{
                headers:{
                    "Authorization":`Bearer ${store.getState().auth.authToken}`
                }
            }).then(response => {
                if(response.data.code === 200){
                    dispatch({
                        type:FETCH_RECEIVEDGIFTS_SUCCESS,
                        payload:{
                            giftReceived:response.data.data.giftcards,
                            offerReceived:response.data.data.vouchers
                        }
                    })
                }
            })  
            const upcomingFlightStatus = (status) => {
                if(['success'].includes(status)){return 'success'};
                if(['in_progress','hold'].includes(status)){return 'in progress'}
                return 'canceled'
            }

Promise.all([eCardEMagOrdersResponse,offersOrdersResponse,physicalGiftcardsOrdersResponse,
    experiencesOrdersResponse,merchantOrdersResponse,upcomingflightsResponse,pastflightsResponse
])
.then(([eCardEMagOrders,
    offersOrders,
    physicalGiftcardOrders,
    experiencesOrder,
    merchantOrders,
    upcomingFlights,
    pastflights

]) => {
const ecardEmag = eCardEMagOrders?.data?.data?.orders?.filter(val => val?.order_status !== "PENDING")

const offerOrder = offersOrders?.data?.data?.orders?.filter(val => val?.order_status !== "PENDING")
const experienceOrder = experiencesOrder?.data?.data?.orders?.filter(val => val.status !== "PENDING")
const mobileTopUpOrder = eCardEMagOrders?.data?.data?.orders?.filter(val => val.order_type === "MOBILE_TOPUP" && val?.order_status !== "PENDING")
const merchantOrder = merchantOrders?.data?.data?.orders?.filter(val => val?.order_status !== "PENDING")
const physicalGiftcardOrder = physicalGiftcardOrders?.data?.data?.orders?.filter(val => val?.order_status !== "PENDING")
const softwareGamesOrder =  eCardEMagOrders?.data?.data?.orders?.filter(val => val?.order_status !== "PENDING" && (val.order_type === 'SOFTWARE_AND_GAME'))
const comingFligtsData=upcomingFlights?.data?.data?.upcomming_flights?.results;
comingFligtsData?.forEach(e => e.booking_status = upcomingFlightStatus(e.booking_status));
const pastflightsData = pastflights?.data?.data?.upcomming_flights?.results


// Commenting for next release
// const softwareOrder = eCardEMagOrders.data.data.orders.filter(val => val.order_status !== "PENDING" && val.reward_type === 'software')
// const gameOrder = eCardEMagOrders.data.data.orders.filter(val => val.order_status !== "PENDING" && val.reward_type === 'game')

dispatch(fetchOrderListSuccess({
eCardEMagOrders:ecardEmag,
mobileTopUpOrders :mobileTopUpOrder,
offersOrders:offerOrder,
experienceOrders :experienceOrder,
merchantOrders:merchantOrder,
physicalGiftcardOrders:physicalGiftcardOrder,
softwareGamesOrder:softwareGamesOrder,
comingFlights:comingFligtsData || [],
pastFlights:pastflightsData || [],


// Commenting for next release

softwareOrders: [],
gameOrders: []
}))
                
            }).catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchOrderListFailure("failed"))
            })
            
    }

}