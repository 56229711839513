import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Breadcrumbs from '../shared/Breadcrumbs'
import travelShopBanner from '../../assests/img/TravelShop/Travel-header.png'
import travelHeaderIcon from '../../assests/img/TravelShop/travel.png'
import hotelIcon from '../../assests/img/TravelShop/hotel.png'
import hotelFlightIcon from '../../assests/img/TravelShop/hotels-flights.png'
import cruiseIcon from '../../assests/img/TravelShop/cruise.png'
import CalendarIcon from '../../assests/img/TravelShop/calendar.png';
import "react-datepicker/dist/react-datepicker.css";
import './TravelShop.css'
import SearchComponent from './SearchComponent';
import Loader from './Loader';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import SelectPassengerModal from "./SelectPassengerModal";
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { fetchAirport, setBrandID, setFilterData, setSearchQueryData, setSearchResults, setSelectedFlightDetails, updatePassengerCount, MobileDevice, ClearSearchResults } from '../../Redux/travelShop/TravelAction'
import {
    RangeDatePicker,
    SingleDatePicker
} from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import axios from 'axios'
import TravelFilter from './TravelFilter'
import TravelShopDetails from './TravelShopDetails'
import { checkTokenValidity, fetchAuthTokenSuccess } from '../../Redux/auth/authActions'
import MainLoginModel from '../shared/Modals/MainLoginModel'
import SearchFlights from './SearchFlights'
import FailMessageModal from '../shared/Modals/FailMessageModal'
import FlightNotFoundModal from '../shared/Modals/FlightNotFoundModal'
import { REACT_APP_baseUrl } from 'utils/app-constants'
import secretKeyGenerator from 'utils/serectKeyGenerator'

class TravelShop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DataViewed:null,
            loading: false,
            brandLoading:true,
            Passenger: "Passenger",
            Class: "Class",
            passengerModal: false,
            startDate: null,
            endDate: null,
            tripType: "OneWay",
            flyingFrom: false,
            flyingTo: false,
            fromPlace: '',
            toPlace: '',
            departureCountry: '',
            passengerCount: {
                adults: this.props.passengerCountDetails.adults || 1,
                child: this.props.passengerCountDetails.child || 0,
                infant: this.props.passengerCountDetails.infant || 0
            },
            flyingToModal: false,
            showPassengerCount: true,
            passengerClass: "Economy",
            clearedDate: false,
            monthShown: 1,
            showDepatureCalendar: true,
            passengerType: '',
            passengerTypeCheck: {
                Economy: true
            },
            validation: {
                flyingFrom: null,
                flyingTo: null,
                departureDate: null
            },
            showSearchForm: true,
            isflightDataFound: true,
            searchError: '',
            limit:10,
            page:0
        }
    }

    paginate = (limit,page) =>{
        this.setState({limit:limit,page:page})
    }

    viewCount = (data) =>{
        this.setState({DataViewed:data})
    }

    brandValidation = () =>{
        if(!this.state.brandLoading){
            this.setState({brandLoading:true})
        }
        let payload = {"brands":{"currency":this.props.countrySelected}}
        axios.post(`${REACT_APP_baseUrl}/brands/flight_brands`,payload,{
                      params:{
                          
                      }
                  })
        .then(response => {
            if(response.data?.data?.brands?.length === 1){
                this.setState({brandLoading:false})
                const brandID = response.data.data?.brands?.[0]?.id
                this.props.setBrandID(brandID);
                this.fetchInitialSearch()
            }
            else{
                this.props.history.push({
                pathname:'/404',
                redirectionURL:'/flights'
            })
            }
        })
        .catch(()=>{
            this.props.history.replace("/404")
        })
    }
    componentDidMount() {
        this.props.checkTokenValidity();
        this.brandValidation()
        localStorage.removeItem("type");
        localStorage.setItem("type", "travel");
        
    }

    fetchInitialSearch = () =>{
        let oneWayTripCheckbox = document.getElementsByClassName('oneWayTripCheckbox');
        if (oneWayTripCheckbox) {
            oneWayTripCheckbox[0].checked = true;
            oneWayTripCheckbox[1].checked = true;
        }
        this.props.fetchAirport()
        if (this.props.searchQueryData.travel_origin_code) {
            const {
                travel_origin_code, travel_destination_code, round_trip_flag, departure_country, tripType, passengerClass, startDate, endDate
            } = this.props.searchQueryData
            this.setState((state) => ({
                ...state,
                fromPlace: travel_origin_code,
                toPlace: travel_destination_code,
                departureCountry: departure_country,
                showSearchForm: !this.props.searchResults?.search?.length,
                tripType,
                passengerClass,
                startDate,
                endDate
            }))
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     // this.disablePrevMonth();

    //     // if(prevProps.countrySelected !== this.props.countrySelected){
    //     //     this.brandValidation()
    //     // }
    // }

    handleEditFlightDetail = () => {
        this.setState({ isflightDataFound: true })
        // this.props.setSearchResults({})
    }

    passengerModal = () => {
        this.setState({
            passengerModal: false
        })
        this.updatePassengerCount()
    }
    flyingToModal = () => {
        this.setState({
            flyingFrom: false,
            flyingTo: false,
        })
    }

    setPlace = (type, value, country_code) => {
        if (type === 'fromPlace') {
            this.setState((state) => ({ ...state, departureCountry: country_code, [type]: value }))
        } else {
            this.setState((state) => ({ ...state, [type]: value }))
        }
    }

    //   Using Icon to open calendar
    openDatepicker = (type) => this[type]?.setOpen(true);


    passengerCountFunction = (passenger, type) => {
        if (
            !(Object.values(this.state.passengerCount).reduce((a, b) => a + b) === 10 && type === 'add')
            && !(type === 'sub' && this.state.passengerCount["adults"] === 1 && passenger === 'adults')) {
            let newPassengerCount = { ...this.state.passengerCount };
            if (type === 'add') { newPassengerCount[passenger]++ }
            else { newPassengerCount[passenger] !== 0 && newPassengerCount[passenger]-- }
            this.setState({ passengerCount: newPassengerCount })
        }
    }

    toggle = () => {
        const { passengerCountDetails } = this.props
        this.setState({ passengerCount: passengerCountDetails })
    }


    passengerTypeChange = (type) => {
        this.setState({
            passengerType: type,
            passengerTypeCheck: {
                [type]: !this.state[type]
            }
        })
    }

    updatePassengerCount = () => {
        this.setState({ showPassengerCount: true });
        this.props.updatePassengerCount(this.state.passengerCount)
    }

    changePassengerClass = (value) => {
        this.setState({ passengerClass: value })
    }

    handleSearchForm = (value) => {
        this.setState({ showSearchForm: value })
    }

    fetchSearch = (payload, segments, order) => {
        const token = localStorage.getItem("userToken");
        payload['search']['currency'] = this.props.currancyCode;
        const data = payload;

        if (this.props.filters) data.search.query_params = this.props.filters

        this.setState({ loading: true })
        axios.post(`${REACT_APP_baseUrl}/travel_shops/fetch_search`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-customer-uuid' : localStorage.getItem("customer_uuid")
                },
            }
        ).then((response) => {
            this.setState({ loading: false })
            if (response.data.status === 'success') {
                const data = { ...response.data.data }
                localStorage.setItem('search_id', response.data.data.search_id)
                if (segments) data.segments = segments
                let allData = {}
                if(this.props.mobileDevice && this.props.searchResults?.search?.length > 0){
                    allData = this.props.searchResults
                    let allFlights = []
                    allFlights = [...this.props.searchResults?.search, ...data?.search]
                    allData.search = allFlights
                }else{
                    allData = data
                }
                this.props.setSearchResults(allData)
                const isflightDataFound = data.search?.length
                if (isflightDataFound) {
                    let filteredAirports = response.data.data?.airports;
                    if (this.props.pickedPlace) {
                        filteredAirports = response.data.data.airports.filter(e => e.city_code !== this.props.pickedPlace)
                    }
                    this.setState({ countries: filteredAirports, loading: false, showSearchForm: false, isflightDataFound })
                } else {
                    this.setState({ loading: false, isflightDataFound })
                    // this.props.setSearchResults({})
                }
            } else {
                if (response.data?.code === 401) {
                    this.props.fetchAuthTokenSuccess('')
                } else {
                    this.setState({ searchError: response.data?.message || 'Something went wrong, please try again later!' })
                }
            }
        }).catch(err => {
            console.log(`err`, err)
            this.setState({ loading: false, searchError: err?.response?.data?.message || 'Something went wrong, please try again later!' })
        })
    }

    validateFlightDetails = (data) => {
        const { fromPlace, toPlace,
            passengerClass, tripType,
            startDate, endDate
        } = data
        const { passengerCount } = this.props

        if (!fromPlace || !toPlace || !passengerClass || !passengerCount) {
            return false
        }
        const roundTrip = tripType === "RoundTrip"
        return roundTrip ? !(!startDate || !endDate) : !!startDate
    }

    handleSearchFn = (device) => {
        if(device){
            this.props.MobileDevice()
            this.props.ClearSearchResults()
        }
        const isValidFlightData = this.validateFlightDetails(this.state)
        if (!isValidFlightData) return

        const { fromPlace, toPlace,
            passengerClass, tripType, departureCountry,
            startDate, endDate
        } = this.state
        const { passengerCountDetails: { adults, child, infant } } = this.props

        const d = new Date(startDate)
        const roundTrip = tripType === "RoundTrip"
        let d1 = null
        if (roundTrip) d1 = new Date(endDate)
        const segments = [
            {
                "origin": fromPlace,
                "destination": toPlace,
                "departure_date": `${d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()}`
            },
            {
                ...(tripType === "RoundTrip") && {
                    "origin": toPlace,
                    "destination": fromPlace,
                    "departure_date": `${d1.getFullYear() + "-" + (d1.getMonth() + 1) + "-" + d1.getDate()}`
                }
            }

        ].filter((value) => Object.keys(value).length !== 0)

        const payload = {
            "search": {
                "departure_country": departureCountry,
                "adult_count": adults,
                "child_count": child,
                "infant_count": infant,
                "cabin_class": passengerClass.toLowerCase(),
                "preferred_airlines": [],
                segments
            }
        }

        const token = localStorage.getItem("userToken");
        this.setState({ loading: true })
        axios.post(`${REACT_APP_baseUrl}/travel_shops/create_search`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            })
            .then(response => {
                if (response.data.data) {
                    if (response.data.data?.search_result?.search_id) {
                        localStorage.setItem('search_id', response.data.data.search_result.search_id)
                        localStorage.setItem('customer_uuid', response.data.data.customer_uuid)
                        const payload = {
                            search: {
                                search_id: response.data.data.search_result.search_id,
                                query_params: {
                                    order: this.props.searchOrder,
                                    limit: this.state.limit,
                                    offset: Number(0)
                                }
                            }
                        }
                        const searchData = {
                            travel_origin_code: fromPlace,
                            travel_destination_code: toPlace,
                            round_trip_flag: tripType === "RoundTrip",
                            departure_country: departureCountry,
                            adult_count: adults,
                            child_count: child,
                            infant_count: infant,
                            passangerCount: (Number(adults) || 0) + (Number(child) || 0) + (Number(infant) || 0),
                            tripType,
                            passengerClass,
                            startDate,
                            endDate,
                            departureDate: `${d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()}`
                        }
                        this.props.setSearchQueryData(searchData)
                        if(device){
                            this.props.setFilterData({
                                "offset":Number(0)
                            })
                        }else{
                            this.props.setFilterData({
                                "limit":this.state.limit,
                                "offset":Number(0)
                            })
                        }
                        setTimeout(() => {
                            this.fetchSearch(payload, segments, this.props.searchOrder)
                        }, 300);
                    } else {
                        this.setState({ loading: false })
                    }
                } else {
                    if (response.data?.code === 401) {
                        this.props.fetchAuthTokenSuccess('')
                        this.setState({ loading: false })
                    } else {
                        this.setState({ loading: false, searchError: response.data?.message || 'Something went wrong, please try again later!' })
                    }
                }
            }).catch(err => {
                this.setState({ loading: false, searchError: err?.response?.data?.message || 'Something went wrong, please try again later!' })
                console.log('err', err)
            })
    }

    handleViewDetail = (result_id) => {
        const token = localStorage.getItem("userToken");
        const search_id = localStorage.getItem("search_id");
        const payload = {
            search: {
                search_id: search_id,
                result_id: result_id,
                update_information: false
            }
        }
        this.setState({ loading: true })
        axios.post(`${REACT_APP_baseUrl}/travel_shops/post_search_by_result_id`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'x-customer-uuid' : localStorage.getItem("customer_uuid")
                },
            }
        )
            .then((response) => {
                axios.get(`${REACT_APP_baseUrl}/travel_shops/get_search_detail?search_id=${search_id}&result_id=${result_id}&currency=${this.props.currancyCode}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                            'x-customer-uuid' : localStorage.getItem("customer_uuid")
                        },
                    }
                ).then((response) => {
                    if (response.data?.data) {
                        localStorage.setItem("result_id", result_id);
                        this.props.setSelectedFlightDetails(response.data?.data)
                        this.setState({ loading: false })
                        this.props.history.push('/flights-details')
                    } else {
                        if (response.data?.code === 401) {
                            this.props.fetchAuthTokenSuccess('')
                            this.setState({ loading: false })
                        } else {
                            this.setState({ loading: false, searchError: response.data?.message || 'Something went wrong, please try again later!' })
                        }
                    }
                    // this.setState({ countries: filteredAirports, loading: false })
                }).catch(err => {
                    console.log(`err`, err)
                    this.setState({ loading: false, searchError: err?.response?.data?.message || 'Something went wrong, please try again later!' })
                })
            })
            .catch((err) => {
                console.log(`err`, err)
                this.setState({ loading: false, searchError: err?.response?.data?.message || 'Something went wrong, please try again later!' })
            })
    };

    DesktopView = () => {
        const DepartureInputField = () => (
            <div style={{ position: 'relative' }} className="dep-group cursor-pointer">
                {this.state.startDate && <span style={{ position: 'absolute', top: '-18px' }} className='text-muted m-0 p-0 travelPlaceHolder'>{t("Departure")}</span>}
                <input style={{ caretColor: 'transparent' }} type="text" className="dep-group border-0 no-outline cursor-pointer" placeholder='Departure' defaultValue={this.state.startDate?.toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })} />
            </div>
        )
        const { t, passengerCountDetails } = this.props
        const { tripType, passengerCount, toPlace, fromPlace, endDate, startDate, isflightDataFound } = this.state
        const breadcrumbs = [
            { name: t("Home"), url: '/', active: true },
            { name: t("Travel Shop"), url: `/flights`, active: false }
        ]
        return (
            <>
                <Breadcrumbs crumbs={breadcrumbs} />
                <div className='row d-md-block d-none'>
                    <div style={{ position: 'relative', paddingBottom: 50 }} className="col-12 d-flex justify-content-center">
                        <img loading='lazy' decoding='async' className='col-12' style={{ backgroundColor: '#4681C2 ', position: 'absolute', top: '0', zIndex: '1%', height: '400px' }} alt="" />
                        <div style={{ backgroundColor: 'white', zIndex: '5%', marginTop: '4%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }} className="col-11 pb-4">
                            <div className="d-flex row">
                                <div className="col-12 d-flex m-0 p-0">
                                    {[["Flights", travelHeaderIcon], ['Hotels', hotelIcon], ['Hotels + Flights', hotelFlightIcon], ['Cruises', cruiseIcon]]
                                        .map((item, index) => (
                                            <div className={`d-flex col-3  py-3 flex-column ${index > 0 && 'bg-grayscale'}`}>
                                                <div className={`d-flex justify-content-center align-items-center`}>
                                                    <img loading='lazy' decoding='async' style={{ height: '30px' }} src={item[1]} alt={item[0]} />
                                                    {
                                                        index === 0 ? <h4 className='color-blue fw-bold mx-2'>{t(item[0])}</h4> :
                                                            <h5 className='text-black-50 fw-bold mx-2'>{t(item[0])}</h5>
                                                    }
                                                </div>
                                                {/* {
                                                    index !== 0 && <div className='d-flex justify-content-end'>
                                                        <span className='color-blue'>{t("Coming Soon")}</span>
                                                    </div>
                                                } */}
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className='col-12 mt-5 px-5 '>
                                    <h4 className='color-blue mb-3 fw-bold'>{t("Search Flights")}</h4>
                                    {/* Section 1 */}
                                    <div className="row">
                                        <div className="col-12 d-flex">
                                            <div className="col-2 border d-flex justify-content-center align-items-center p-2 mx-2">
                                                <input onClick={(e) => { this.setState({ tripType: 'RoundTrip' }) }} name='tripTypeCheckbox' checked={this.state.tripType === 'RoundTrip'} type="radio" className="mx-2 travelShopRadio" />
                                                <h6 className=
                                                    {`text-black-50 ${this.props.lang === "ar" ? "" : "mt-2"
                                                        } `}

                                                >{t("Round Trip")}</h6>
                                            </div>
                                            <div className="col-2 border d-flex justify-content-center align-items-center p-2 mx-2">
                                                <input onClick={(e) => { this.setState({ tripType: 'OneWay' }) }} name='tripTypeCheckbox' checked={this.state.tripType === 'OneWay'} type="radio" className="mx-2 travelShopRadio oneWayTripCheckbox" />
                                                <h6 className={`text-black-50 ${this.props.lang === "ar" ? "" : "mt-2"
                                                    } `}>{t("One way")}</h6>
                                            </div>
                                            <div className="col-4 mx-2 p-2 border d-flex align-items-center cursor-pointer" id="passengerToggle">


                                                <Dropdown onToggle={() => this.toggle()}>
                                                    <Dropdown.Toggle id="dropdown-basic" className='bg-transparent border-0 text-black-50 w-100 d-flex align-items-center justify-content-between no-outline'>
                                                        <h6 style={{ display: 'inline-block' }}>{`${passengerCountDetails.adults} ${t("Adult")} ${passengerCountDetails.child > 0 ? `, ${passengerCountDetails.child} ${t("Child")}` : ''} ${passengerCountDetails.infant > 0 ? `, ${passengerCountDetails.infant} ${t("Infant")}` : ''}`}</h6>
                                                        {/* <h6 style={{ display: 'inline-block' }}>{this.state.showPassengerCount ?
                                                            `${passengerCountDetails.adults} Adult ${passengerCountDetails.child > 0 ? `, ${passengerCountDetails.child} Child` : ''} ${passengerCountDetails.infant > 0 ? `, ${passengerCountDetails.infant} Infant` : ''}`
                                                            : `${t("Passengers")}`}</h6> */}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='w-100 px-2 mt-1'>
                                                        <div className='bg-transparent d-flex justify-content-between align-items-center row mx-2'>

                                                            <p className='col-8 text-dark fw-bold'>{t("Adults")} <small className='text-black-50 fw-bold'>{t("(12+ years)")}</small></p>
                                                            <div className='d-flex col-4 justify-content-between'>
                                                                <p onClick={() => this.passengerCountFunction('adults', 'sub')} style={{ fontSize: '1.2em' }} className='text-black-50 py-1 btn'>-</p>
                                                                <p style={{ fontSize: '1.2em' }} className='py-1'>{passengerCount.adults}</p>
                                                                <p onClick={() => this.passengerCountFunction('adults', 'add')} style={{ fontSize: '1.2em' }} className='color-blue py-1 btn' >+</p>
                                                            </div>
                                                        </div>
                                                        <div className='bg-transparent d-flex justify-content-between align-items-center row mx-2'><p className='col-8 text-dark fw-bold'>{t("Child")} <small className='text-black-50 fw-bold'>{t("(2 - 12 years)")}</small></p>
                                                            <div className='d-flex col-4 justify-content-between'>
                                                                <p onClick={() => this.passengerCountFunction('child', 'sub')} style={{ fontSize: '1.2em' }} className='text-black-50 py-1 btn'>-</p>
                                                                <p style={{ fontSize: '1.2em' }} className='py-1'>{passengerCount.child}</p>
                                                                <p onClick={() => this.passengerCountFunction('child', 'add')} style={{ fontSize: '1.2em' }} className='color-blue py-1 btn'>+</p>
                                                            </div></div>
                                                        <div className='bg-transparent d-flex justify-content-between align-items-center row mx-2'>
                                                            <p className='col-8 text-dark fw-bold'>{t("Infants")} <small className='text-black-50 fw-bold'>{t("(below 2 years)")}</small></p>
                                                            <div className='d-flex col-4 justify-content-between'>
                                                                <p onClick={() => this.passengerCountFunction('infant', 'sub')} style={{ fontSize: '1.2em' }} className='text-black-50 py-1 btn'>-</p>
                                                                <p style={{ fontSize: '1.2em' }} className='py-1'>{passengerCount.infant}</p>
                                                                <p onClick={() => this.passengerCountFunction('infant', 'add')} style={{ fontSize: '1.2em' }} className='color-blue py-1 btn'>+</p>
                                                            </div>
                                                        </div>
                                                        <Dropdown.Item className='border-0 no-outline bg-transparent'><button style={{ border: '2px solid darkblue' }} className='btn w-100' onClick={this.updatePassengerCount}><span className='fw-bold color-blue'>{t("Confirm")}</span></button></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="col-3 mx-2 p-2 border d-flex align-items-center cursor-pointer">
                                                <Dropdown>
                                                    <Dropdown.Toggle className='bg-transparent border-0 text-black-50 w-100 d-flex align-items-center justify-content-between no-outline' id="dropdown-basic">
                                                        <h6 style={{ display: 'inline-block' }}>{t(this.state.passengerClass)}</h6>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='w-100 px-2 mt-1'>
                                                        {
                                                            ["Economy", "Business", "First"].map(item => <Dropdown.Item className='text-dark fw-bold' onClick={() => { this.setState({ passengerClass: item }) }}>{t(item)}</Dropdown.Item>)
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Section 2 */}
                                    <div className='my-3 mx-2 row'>
                                        <div className={`travelSection2 ${tripType === 'OneWay' ? 'OneWaytravelSection2' : 'twoWaytravelSection2'}`}>
                                            <SearchComponent key={'fromPlace'} type={'Flying From'} status={'fromPlace'} setPlace={this.setPlace} value={fromPlace} pickedPlace={toPlace} lang={this.props.lang} />
                                            <SearchComponent key={'toPlace'} type={'Flying To'} status={'toPlace'} setPlace={this.setPlace} value={toPlace} pickedPlace={fromPlace} lang={this.props.lang} />

                                            {/* Date picker  */}
                                            {
                                                tripType === "RoundTrip" ?
                                                    <>
                                                        <div className=' px-2'>
                                                            <RangeDatePicker
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                minDate={new Date()}
                                                                startDatePlaceholder={`${t("Departure")}`}
                                                                endDatePlaceholder={`${t("Return")}`}
                                                                onChange={(startDate, endDate) => {
                                                                    this.setState({ startDate, endDate })
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className=' px-2'>
                                                            <SingleDatePicker
                                                                minDate={new Date()}
                                                                startDatePlaceholder={`${t("Departure")}`}
                                                                startDate={startDate}
                                                                singleCalendar={true}
                                                                onChange={(dates) => {
                                                                    this.setState({ startDate: dates })
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mx-4 justify-content-end'>
                                        <button className='btn btn-sky rounded-0 mx-3' onClick={()=>this.handleSearchFn(false)} disabled={!this.validateFlightDetails(this.state)}>{this.props.searchResults?.search?.length ? t("Change Details") : t("Search Flights")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray py-4 px-xl-5 d-md-block d-none desktop_view">
                    <Container fluid className="sidebarmain mt-4">
                        <Row className="mt-4">
                            {
                                this.props.searchResults?.filtered_metadata ?
                                    <Col lg={3}>
                                        <TravelFilter mobileDevice={this.props.mobileDevice} limit={this.state.limit} page={this.state.page} fetchSearch={this.fetchSearch} from={this.state.DataViewed} viewCount={this.viewCount} paginate={this.paginate}/>
                                    </Col> : null
                            }
                            <Col lg={9}>
                                {this.props.searchResults?.filtered_metadata ?
                                    this.props.searchResults?.search?.length ?
                                        <TravelShopDetails fetchSearch={this.fetchSearch} handleViewDetail={this.handleViewDetail} viewCount={this.viewCount} paginate={this.paginate} mobileDevice={this.props.mobileDevice}
                                        />
                                        : <div className="flights-box border text-center p-5">No flight data was found for the applied filters!</div>
                                    : null
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }

    MobileView = () => {
        const { t, passengerCountDetails } = this.props
        const { tripType, passengerCount, toPlace, fromPlace, endDate, startDate, showSearchForm } = this.state
        return (<>
            {
                showSearchForm ?
                    <>
                        <div className="d-block d-md-none">
                            <div className="bg-color-blue2 row py-3">
                                <div className="col-4 text-center  d-flex justify-content-center align-items-center gap-2">
                                    <img loading='lazy' decoding='async' height={'30px'} src={travelHeaderIcon} alt="travel header" />
                                    {t("Flights")}</div>
                                <div className="col-4 text-center d-flex justify-content-center align-items-center gap-2">
                                    <img loading='lazy' decoding='async' src={hotelIcon} alt="" />
                                    {t("Hotels")}
                                </div>
                                <div className="col-4 text-center justify-content-center align-items-center gap-2">
                                    <img loading='lazy' decoding='async' src={cruiseIcon} alt="" />
                                    {t("Cruises")}
                                </div>
                            </div>
                            <div className="px-3 py-3 d-flex">
                                <div className="mr-3 d-flex justify-content-center align-items-center">
                                    <input className="d-inline mr-2 travelShopRadio" type="radio"
                                        onClick={(e) => { this.setState({ tripType: 'RoundTrip' }) }} checked={this.state.tripType === 'RoundTrip'} name='tripTypeCheckboxMobile'
                                    />
                                    <label className='my-0 mx-2' for="html">{t("Round Trip")}</label>
                                </div>
                                <div className="mr-3 d-flex justify-content-center align-items-center">
                                    <input className="d-inline mr-2 
              travelShopRadio oneWayTripCheckbox"
                                        checked={this.state.tripType === 'OneWay'}
                                        onClick={(e) => { this.setState({ tripType: 'OneWay' }) }} name='tripTypeCheckboxMobile'
                                        type="radio"
                                    />
                                    <label className='my-0 mx-2' for="html">{t("One way")}</label>
                                </div>
                            </div>
                            <div className='py-3 px-2'>
                                <div className="border w-auto mx-3" onClick={() => this.setState({ flyingFrom: true })}>
                                    <h6 className='px-3 py-3'>{this.state.fromPlace || `${t("Flying From")}`}</h6>
                                </div>
                                <div className="border w-auto mx-3 mt-4" onClick={() => this.setState({ flyingTo: true })}>
                                    <h6 className='px-3 py-3'>{this.state.toPlace || `${t("Flying To")}`}</h6>
                                </div>
                                <div className=
                                    {`"border w-auto mx-3 mt-4 " ${this.props.lang === "ar" ? "arrow-down-arabic" : "arrow-down"
                                        } `}
                                    onClick={() => this.setState({ passengerModal: true })}
                                >
                                    <h6 className='p-3' style={{ display: 'inline-block' }}>{`${passengerCountDetails.adults} ${t("Adult")} ${passengerCountDetails.child > 0 ? `, ${passengerCountDetails.child} ${t("Child")}` : ''} ${passengerCountDetails.infant > 0 ? `, ${passengerCountDetails.infant} ${t("Infant")}` : ''}`} {t(this.state.passengerClass)} </h6>

                                </div>
                            </div>
                            <div>
                                {
                                    tripType === "RoundTrip" ?
                                        <>
                                            <div className='py-4 px-2'>
                                                <RangeDatePicker
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={new Date()}
                                                    startDatePlaceholder={`${t("Departure")}`}
                                                    endDatePlaceholder={`${t("Return")}`}
                                                    onChange={(startDate, endDate) => {
                                                        this.setState({ startDate, endDate })
                                                    }}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='py-4 px-2'>
                                                <SingleDatePicker
                                                    minDate={new Date()}
                                                    startDatePlaceholder={`${t("Departure")}`}
                                                    startDate={startDate}
                                                    singleCalendar={true}
                                                    onChange={(dates) => {
                                                        this.setState({ startDate: dates })
                                                    }}

                                                />
                                            </div>
                                        </>
                                }
                            </div>
                            <div className='  text-center mx-3'>
                                <button className='btn primary-btn rounded-0 w-100' onClick={()=>this.handleSearchFn(true)} >{t("Search Flights")}</button>
                            </div>
                        </div>
                    </>
                    :

                    this.props.searchResults ?
                        <div className="bg-gray d-block d-md-none mobile_view">
                            <Container fluid className="sidebarmain p-0">
                                <Row>
                                    <Col lg={12}>
                                        <TravelFilter mobileDevice={this.props.mobileDevice} limit={this.state.limit} page={this.state.page} fetchSearch={this.fetchSearch} handleViewDetail={this.handleViewDetail} handleSearchForm={this.handleSearchForm} from={this.state.DataViewed} viewCount={this.viewCount} paginate={this.paginate}/>
                                    </Col>
                                </Row>
                            </Container>
                        </div> : null
            }
        </>
        )
    }
    render() {
        const { tripType, passengerCount, toPlace, fromPlace, endDate, startDate, searchError, isflightDataFound ,brandLoading} = this.state
        return (
            <div>
                {
                    true ? "" : <MainLoginModel lang={this.props.lang} show={true} loginFun={() => this.props.history.push('/')} toogleLang={this.props.toogleLang}/>
                }
                {
                    searchError ? <FailMessageModal show={!!searchError} Message={searchError} customError onClose={() => {this.setState({searchError:""})}} ShowheaderText={false} path="/flights"/> : null
                }
                {
                    isflightDataFound ? null : <FlightNotFoundModal show={!isflightDataFound} handleEditFlightDetail={this.handleEditFlightDetail} />
                }
                {(this.state.loading || brandLoading) && <Loader />}
                <SelectPassengerModal
                    show={this.state.passengerModal}
                    showModal={this.passengerModal}
                    passengerCount={this.state.passengerCount}
                    passengerCountFunction={this.passengerCountFunction}
                    passengerTypeChange={this.passengerTypeChange}
                    passengerType={this.state.passengerType}
                    passengerTypeCheck={this.state.passengerTypeCheck}
                    changePassengerClass={this.changePassengerClass}
                    passengerClass={this.state.passengerClass}
                />
                <this.MobileView />
                <div className='d-block d-md-none'>
                    <SearchComponent type={'Flying From'} status={'fromPlace'} setPlace={this.setPlace} pickedPlace={toPlace} value={fromPlace} showModal={this.flyingToModal} show={this.state.flyingFrom} lang={this.props.lang} />
                    <SearchComponent type={'Flying To'} status={'toPlace'} setPlace={this.setPlace} pickedPlace={fromPlace} showModal={this.flyingToModal} value={toPlace} show={this.state.flyingTo} lang={this.props.lang} />
                </div>
                <this.DesktopView />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        contactDetails: state.travel.contactDetails,
        searchResults: state.travel.searchResults,
        mobileDevice : state.travel.mobileDevice,
        searchOrder: state.travel.searchOrder,
        passengerCountDetails: state.travel.passengerCountDetails,
        passengerCount: state.travel.passengerCount,
        searchQueryData: state.travel.searchQueryData,
        filters: state.travel.filters,
        authToken: state.auth.authToken,
        currancyCode:state.countries.convertionRate.currency_code,
        countrySelected:state.countries.countryId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updatePassengerCount: (passengerCount) => dispatch(updatePassengerCount(passengerCount)),
        fetchAirport: () => dispatch(fetchAirport()),
        setSearchResults: (data) => dispatch(setSearchResults(data)),
        setSearchQueryData: (data) => dispatch(setSearchQueryData(data)),
        checkTokenValidity: () => dispatch(checkTokenValidity()),
        setSelectedFlightDetails: (data) => dispatch(setSelectedFlightDetails(data)),
        fetchAuthTokenSuccess: (data) => dispatch(fetchAuthTokenSuccess(data)),
        setFilterData: (data) => dispatch(setFilterData(data)),
        setBrandID: (brandID) => dispatch(setBrandID(brandID)),
        MobileDevice: () => dispatch(MobileDevice()),
        ClearSearchResults: () => dispatch(ClearSearchResults())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(TravelShop)));