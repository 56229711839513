import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { FETCH_GIFTCARDS_FAILURE, FETCH_GIFTCARDS_REQUEST, FETCH_GIFTCARDS_SUCCESS} from "./giftCardTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';


export const fetchGiftCardsRequest = () => {
    return {
        type:FETCH_GIFTCARDS_REQUEST,
    }
}

export const fetchGiftCardsSuccess = data => {
    return {
        type:FETCH_GIFTCARDS_SUCCESS,
        payload:data
    }
}

export const fetchGiftCardsFailure = error =>{
    return {
        type:FETCH_GIFTCARDS_FAILURE,
        payload:error
    }
}


// const cacheHeaders = () => {
//     return {
//         headers: {
//             'Content-Type': 'application/json',
//             'Cache-Control': 'no-cache, no-store, must-revalidate',
//             'Expires': '0'
//         }
//     }
//   }

export const fetchGiftCards = (payload) => {
    return (dispatch)=>{
        dispatch({type:FETCH_GIFTCARDS_REQUEST})
        dispatch(fetchGiftCardsRequest);
        axios.post(`${REACT_APP_baseUrl}/brands/get_brands`,
            payload,
            {
                params:{
                    
                }
            })
            .then(response  => {
                // const giftCards = []
                let categories = response.data.data.categories;
                // categories.forEach(item => {
                //     item.brands.forEach(card => {
                //         giftCards.push(card)
                //     });
                // })
                dispatch(fetchGiftCardsSuccess(categories))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchGiftCardsFailure("failed"))
            })
    }
}


