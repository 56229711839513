import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { FETCH_EXPERIENCE_DETAILS, FETCH_EXPERIENCE_FAILURE, FETCH_EXPERIENCE_IMAGES, FETCH_EXPERIENCE_REQUEST, FETCH_EXPERIENCE_SUCCESS,FETCH_EXPERIENCE_TERMS, FETCH_EXPERIENCE_DETAILS_SUCCESS, FETCH_IMAGES_REQUEST, FETCH_IMAGES_FAILURE } from "./experienceTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';


export const fetchExperienceCardsRequest = () => {
    return {
        type:FETCH_EXPERIENCE_REQUEST,
    }
}

export const fetchExperienceCardsSuccess = (data,featuredCards) => {
    return {
        type:FETCH_EXPERIENCE_SUCCESS,
        payload:{
            data: data,
            featuredCards: featuredCards
        }
    }
}

export const fetchExperienceCardsFailure = error =>{
    return {
        type:FETCH_EXPERIENCE_FAILURE,
        payload:error
    }
}

export const fetchExperienceCards = (payload) => {
    return (dispatch)=>{
        dispatch({type:FETCH_EXPERIENCE_REQUEST})
        dispatch(fetchExperienceCardsRequest);
        axios.post(`${REACT_APP_baseUrl}/experiences/get_experiences`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    
                }
            })
            .then(response  => {
                if(response.data?.code === 200){
                    let featuredExpCards = [];
                    let experienceCards = [];
                    if(response.data.data.categories[0]){
                        const experiencesData = response.data?.data?.categories;
                        experiencesData.forEach(e => experienceCards.push(e.experiences))
                        experienceCards[0]?.forEach(e => e.featured_flag && featuredExpCards.push(e))
                        experienceCards[0].sort(function(a, b){
                            return a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                        })
                    dispatch(fetchExperienceCardsSuccess(experienceCards[0],featuredExpCards))
                    }
                    else{
                        dispatch(fetchExperienceCardsSuccess(response.data?.data?.categories))
                    }
                }
                else{
                    dispatch(fetchExperienceCardsFailure(response.data?.message))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchExperienceCardsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchExperienceDetails = (payload) => {
    
    return (dispatch)=>{
        dispatch({type:FETCH_EXPERIENCE_DETAILS})
        // dispatch(fetchExperienceCardsRequest);
        axios.post(`${REACT_APP_baseUrl}/experiences/experience_details`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    
                }
            })
            .then(response  => {             
                if(response.data?.code === 200){
                    return dispatch({
                        type: FETCH_EXPERIENCE_DETAILS_SUCCESS,
                        payload:{
                           experienceDetails : response.data?.data?.experience
                        }
                    })
                }
                else{
                    dispatch(fetchExperienceCardsFailure(response.data?.message))
                }
                
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchExperienceCardsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchExperienceTerms = (payload) => {
    return (dispatch)=>{
        axios.post(`${REACT_APP_baseUrl}/experiences/experience_terms`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    
                }
            })
            .then(response  => {    
               
                return dispatch({
                    type: FETCH_EXPERIENCE_TERMS,
                    payload:{
                        experienceTerms : response.data?.data
                    }
                })
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
            })
    }
}

export const fetchExperienceImages = (payload) => {
   
    return (dispatch)=>{
        dispatch({type: FETCH_IMAGES_REQUEST});
        axios.post(`${REACT_APP_baseUrl}/experiences/experience_images`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    
                }
            })
            .then(response  => { 
                
                
                if(response.data?.code === 200){
                    return dispatch({
                        type: FETCH_EXPERIENCE_IMAGES,
                        payload:{
                            experienceImages : response.data?.data?.experience_images
                        }
                    })
                }
                else{
                    dispatch({
                        type:FETCH_IMAGES_FAILURE,
                        payload:response.data?.message
                    })
                }
               
            })
            .catch(error => {
                dispatch({
                    type:FETCH_IMAGES_FAILURE,
                    payload:"Something went wrong!! Please try again!!"
                })
            })
    }
}