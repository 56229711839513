import React, { Component } from "react";
// import Breadcrumbs from "../shared/Breadcrumbs";
// import TAndConly from "../shared/T&Conly";
// import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import secretKeyGenerator from "../../utils/serectKeyGenerator";
import axios from "axios";
// import { currancyToPoints } from "../../utils/pointsConversion";
// import { Link } from "react-router-dom";
import Apple_Wallet from "../../assests/img/Apple_Wallet.png";
import i18next from "i18next";
import thumbLogo from "../../assests/img/header/logo2.svg";
import enfactem from "../../assests/img/header/enfactemlogo.png"
import facbookSvg from "../../assests/img/social-media/facebook.svg";
import twitterSvg from "../../assests/img/social-media/twitter.svg";
import instagramSvg from "../../assests/img/social-media/instagram.svg";
import globeSvg from "../../assests/img/social-media/globe.svg";
import phoneIcon from "../../assests/img/Footer/whatsapp.svg";
import { REACT_APP_baseUrl } from 'utils/app-constants';
import { logoHandler } from "utils/Theme/Logo-handler";
import { tradeMarkHandler } from "utils/Theme/theme";

class ViewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: {}
    };
  }

  componentDidMount = () => {
    let urlLang = this.props.location?.search?.split("=")[1];
    if (urlLang !== this.props.lang && (urlLang === "en" || urlLang === "ar")) {
      i18next.changeLanguage(urlLang);
      this.props.setLang(urlLang);
      localStorage.setItem("lang", urlLang);
    }
    const token = this.props.match.params.token;
    const payload = {
      giftcards: {
        giftcard_token: token,
      },
    };
    axios
      .post(`${REACT_APP_baseUrl}/giftcards/card_by_token`, payload, {
        // headers:{
        //     "Authorization":`Bearer ${this.props.authToken}`
        // },
        params: {
          
        },
      })
      .then((response) => {
        if (response.data.code === 200) {
          this.setState({
            details: response.data.data.giftcard,
          });
        }
      })
      .catch((error) => {
        // this.setState({paymentFail:true})
      });
    // this.setState({loading:true})
  };
  numberWithCom = (x) => {
    let num = +x;
    let y = num.toFixed(0);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  // Detect Hyperlink form Trems
  detectURLs(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.split(urlRegex)
      .map(part => {
        if (part?.match(urlRegex)) {
          return <a href={part} key={part} target="_blank" rel="noreferrer" > {part} </a>;
        }
      else{
            return part;
      }
      });
  } 
  
  getFullYear=()=>{
    const year=new Date().getFullYear();
    return year;
}
  render() {
    let socialMediaLinks = [
        
      {id:'phone',url:'http://wa.me/971527682403',imgSrc:phoneIcon},
      {id:'globe',url:"https://www.flexxpay.com",imgSrc:globeSvg},
      {id:'facebook',url:"https://facebook.com/flexxpay",imgSrc:facbookSvg},
      {id:'twitter',url:"https://twitter.com/flexxpay",imgSrc:twitterSvg},
      {id:'instagram',url:"https://www.instagram.com/flexxpay",imgSrc:instagramSvg},
  ]
    const card = this.state.details;
    const { t } = this.props;
    return (
      <>
        {!card.card_image ? (
          <section>
            <div class="d-flex justify-content-center pt-4 pb-3 mt-4 mb-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </section>
        ) : (
          <section>
            {/* <Breadcrumbs crumbs={breadcrumbs}/> */}

            {/* !this.props.loading && */}
            <header className="viewDetails py-1 pb-2 mx-2">
              <img
                src={logoHandler()}
                style={{ width: "150px" }}
                class=""
                alt="logo"
                srcSet=""
              />
            </header>
            <div className="p-md-3 py-3 d-flex flex-md-row flex-column justify-content-center">
              <div className="p-0 d-md-block  text-center">
                {   card?.emagazine_flag === 1 ? (
                  <span className="text-black-50">
                    {" "}
                    <h5>{t("Please find below your E-Magazine")}</h5>
                  </span>
                ) : (
                  // brand_name
                  <div className="mx-5">
                    {" "}
                    <p style={{ color: "#747c91" }}>
                      {" "}
                      {this.props.lang !== "ar"
                        ? `Please find below your ${card?.brand_name} Gift Card with a value of`
                        : `برصيد ${card?.brand_name_arabic} يمكنك استخدام بطاقة الهدايا الخاصة بك الموجودة أدناه من`}{" "}
                      <b>
                        {" "}
                        {this.numberWithCom(card.original_value)}{" "}
                        {this.props.lang !== "ar"
                          ? card?.currency_name
                          : card?.currency_name_arabic}
                      </b>{" "}
                    </p>
                    {/* {this.numberWithCommas(currancyToPoints(giftcard_value * quantity))} */}
                  </div>
                )}

                <div className="pt-3 pb-1">
                  <img
                    style={{ width: "250px" }}
                    className="img-fluid p-0 mx-auto d-block"
                    src={card?.card_image}
                    alt=""
                  />
                  {card?.emagazine_flag === 0 &&
                    card?.third_party_giftcard_url !== null && (
                      <>
                        <div className="pt-3">
                          <button className="border-0 primary-btn p-2">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="nohover text-white text-decoration-none"
                              href={card?.third_party_giftcard_url}
                            >
                              {t("Please click to see your Gift Card details")}
                            </a>
                          </button>
                        </div>
                      </>
                    )}
                  <div className="py-2">
                    {card?.barcode_image && (
                      <>
                        <img
                          src={`data:image/png;base64,${card?.barcode_image.content}`}
                          alt="barcode"
                        />
                      </>
                    )}
                  </div>
                  {/* {
                          card.emagazine_flag !== 1 && <div style={{color:'#747c91',fontSize:'18px'}}>{card.giftcard_number}</div>
                      }
                       */}
                  {card?.emagazine_flag === 1 && (
                    <button className="border-0 primary-btn p-2">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nohover text-white text-decoration-none"
                        href={card?.third_party_giftcard_url}
                      >
                        {t("Please click to view your E-Magazine")}
                      </a>
                    </button>
                  )}
                </div>
                {card?.emagazine_flag === 0 &&
                  card?.third_party_giftcard_url !== null &&
                  card?.giftcard_pin !== null && (
                    <div
                      style={{ color: "#747c91", fontSize: "16px" }}
                      className=""
                    >
                      {t("Pin/Challenge Token")} : {card?.giftcard_pin}
                    </div>
                  )}
                {card?.third_party_giftcard_url == null && (
                  <>
                    <div
                      style={{ color: "#747c91", fontSize: "18px" }}
                      className="pb-3"
                    >
                      {card?.giftcard_number}
                    </div>
                    {card?.giftcard_pin !== null && (
                      <div
                        style={{ color: "#747c91", fontSize: "16px" }}
                        className="pb-3"
                      >
                        {t("Pin/Challenge Token")} : {card?.giftcard_pin}
                      </div>
                    )}
                    {card?.expiration_date == null ? (
                      false
                    ) : (
                      <div
                        style={{ color: "#747c91", fontSize: "16px" }}
                        className="pb-3 pl-2"
                      >
                        {t("Expiry Date")} : {card.expiration_date}
                      </div>
                    )}
                    {card?.emagazine_flag !== 1 &&
                      card?.third_party_giftcard_url === null && (
                        <a href={card?.pkpass_link}>
                          <img
                            style={{ width: "180px" }}
                            className="img-fluid p-0 pl-2 mx-auto d-block cursor-pointer"
                            src={Apple_Wallet}
                            alt=" "
                          />
                        </a>
                      )}
                  </>
                )}
              </div>
            </div>
            <div className="p-2">
              <h4 className=" fs-5 mb-2 primary-color px-3"> {t("Terms")}</h4>
              {this.props.lang !== "ar" ? (
                <div className="px-3 py-3  " style={{ maxWidth: "700px" }}>
                  {card?.terms &&
                    Object.values(card?.terms)[0]?.map((terms) =>
                      terms?.terms_text
                        ?.split("\n")
                        ?.map((term) => <div>{this.detectURLs(term)}</div>)
                    )}
                </div>
              ) : (
                <div className="p-2" style={{ maxWidth: "700px" }}>
                  {card?.terms &&
                    Object.values(card?.terms)[1]?.map((terms) =>
                      terms?.terms_text
                        ?.split("\n")
                        ?.map((term) => <div>{this.detectURLs(term)}</div>)
                    )}
                </div>
              )}
            </div>
            <footer className="text-center header_footer">
              {/* <div className="d-flex justify-content-center text-center p-4">
                {socialMediaLinks.map((link) => (
                  <div className="px-2" key={link.id}>
                    <a href={link.url} target="_blank" rel="noreferrer">
                      <img
                        className="img-fluid"
                        style={{ height: "2.0rem" }}
                        src={link.imgSrc}
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div> */}
              <div className="p-4 text-white">
                {`${t(`© ${this.getFullYear()} ${tradeMarkHandler()}`)}`} {`${t(`All Rights Reserved.`)}`}
              </div>
            </footer>
          </section>
        )}
        {/* } */}
      </>
    );
  }
}

export default withRouter(withTranslation()(ViewDetails));
