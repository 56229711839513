import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { FETCH_GAMES_DETAILS_FAILURE, FETCH_GAMES_DETAILS_REQUEST, FETCH_GAMES_DETAILS_SUCCESS, FETCH_GAMES_FAILURE, FETCH_GAMES_REQUEST, FETCH_GAMES_SUCCESS, FETCH_GAMES_TERMS_SUCCESS } from "./GamesTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';



export const fetchGames = (payload) => {
    return (dispatch) =>{
        dispatch({type: FETCH_GAMES_REQUEST})
        axios.post(`${REACT_APP_baseUrl}/software_and_games/get_sg_by_type`,
        payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    
                }
            })
        .then(response => {
            if(response.data?.code === 200){
                if(response.data.data?.categories[0]){
                    let featuredFlags = [];
                    response.data?.data?.categories?.forEach(e => featuredFlags.push(e.software_and_games))
                    let featProducts =  featuredFlags[0]?.filter((e)=> e.featured_flag)
                dispatch({
                    type:FETCH_GAMES_SUCCESS,
                    payload:{
                        data:response.data?.data?.categories,
                        featuredProducts:featProducts
                    }
                })
                }
                else{
                    dispatch({
                        type:FETCH_GAMES_FAILURE,
                        payload: response.data?.message
                    })
                }
            }
            else{
                dispatch({
                    type: FETCH_GAMES_FAILURE,
                    payload: response.data?.message
                })
            }
            })
        .catch(err => {
            dispatch({
                type: FETCH_GAMES_FAILURE,
                payload: "Something went wrong!! Please try again!!"
            })
        })
    }
}


export const fetchGamesDetails = (payload) => {
    return (dispatch) =>{
        dispatch({type:FETCH_GAMES_DETAILS_REQUEST})
        axios.post(`${REACT_APP_baseUrl}/software_and_games/software_and_game_details`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    
                }
            })
            .then(response => {
                if(response.data?.code === 200){
                    dispatch({
                        type: FETCH_GAMES_DETAILS_SUCCESS,
                        payload: response.data?.data?.software_and_game
                    })
                }
                else{
                    dispatch({
                        type: FETCH_GAMES_DETAILS_FAILURE,
                        payload:response.data?.message
                    })
                }
            })
            .catch(err => {
                dispatch({
                    type: FETCH_GAMES_DETAILS_FAILURE,
                    payload: 'Something went wrong!! Please try again!!'
                })
            })
    }
}


export const fetchGamesTerms = (payload) => {
    return (dispatch)=>{
        axios.post(`${REACT_APP_baseUrl}/software_and_games/software_and_game_terms`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    
                }
            })
            .then(response  => {    
                return dispatch({
                    type: FETCH_GAMES_TERMS_SUCCESS,
                    payload:response.data?.data.terms
                })
            })
    }
}