import axios from 'axios';
import Loader from 'components/shared/Loader';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { REACT_APP_baseUrl } from 'utils/app-constants';
import { setCountryHandler, setLanguagesHandler } from '../../Redux/country/countriesAction';
import secretKeyGenerator from 'utils/serectKeyGenerator';
import UserPreferenceSuccessModel from 'components/shared/Modals/UserPreferenceSuccessModel';


class Myprofile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordError:"",
            password:"",
            confirmPassword:"",
            loading:true,
            profileInfo:null,
            selectedCountryID:"",
            languages:"",
            userProfile:null,
            selectedUserCountry:{},
            userLang:"",
            show:false
        };
      }

      validatePassword = () =>{
        const validPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$");
        if(!validPassword.test(this.state.password)){
            this.setState({passwordError:"invalid"})
        }else{
            this.setState({passwordError:""})
        }
      }

      handleChangePassword = () =>{
          if(this.state.password !== this.state.confirmPassword){
              this.setState({passwordError:"mismatch"})
          }else{
              this.setState({passwordError:""})
          }
      }

      componentDidMount(){
        if(!this.props.valid){
            this.props.history.replace("/")
        }
        else{
            this.handleMyProfileAPI()
        }
      }

    handleMyProfileAPI(){
        let token = localStorage.getItem("userToken");
        axios.post(`${REACT_APP_baseUrl}/user/profile`,'',{
            headers:{
                "Authorization":`Bearer ${token}`
            }
        })
        .then((response) => {
            if(response.data.code === 200){
                this.setState({profileInfo: response.data.data?.profile,loading:false},()=>{
                    this.setUserCountryHandler(this.state.profileInfo?.currency_id, this.state.profileInfo?.language_code)
                })
            }
            else{
                this.setState({loading:false})
            }
        })
        .catch((err) => {
            this.setState({loading:false})
            throw new Error("Profile API error")
        })
    }
    setUserCountryHandler = (id, lang) => {
        let country = this.props.countries?.filter(ite => ite?.currencies?.[0]?.id == id)
        let selecteCountry={
            countryName: country?.[0]?.name,
            countryId: country?.[0]?.id,
            currencyId: country?.[0]?.currencies?.[0]?.id,
            countryCode: country?.[0]?.code,
            currencyCode: country?.[0]?.currencies?.[0]?.code,
        }
        this.setState({ selectedUserCountry:selecteCountry, selectedCountryID: id, languages: country?.[0]?.languages, userLang:lang ? lang : country?.[0]?.languages?.[0]?.code })
        
    }
    toogleLang = (value) => {
        this.setState({userLang:value})
    }
    handleUserPreference = () => {
        this.setState({loading:true})
        const {selectedCountryID,languages,selectedUserCountry,userLang} = this.state
        let token = localStorage.getItem("userToken");
        let payload={
            user:{
                country_code:selectedUserCountry.countryCode,
                currency_code:selectedUserCountry.currencyCode,
                language_code:userLang
            }
        }
        axios.post(`${REACT_APP_baseUrl}/user/update_profile`,payload,{
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params: {
              
            },
        })
        .then((response) => {
            if(response.data.code === 200){
                let selectedCountry = {
                    countryName: selectedUserCountry?.countryName,
                    countryId: selectedUserCountry?.countryId,
                    currencyId: selectedUserCountry?.currencyId,
                    countryCode: selectedUserCountry?.countryCode,
                    currencyCode: selectedUserCountry?.currencyCode
                };
                const userPayload = {
                    selectedCountry,
                    languages: languages,
                }
                this.setState({userProfile:response?.data?.data?.profile})
                this.setState({loading:false,show:true})
                this.props.toogleLang(userLang)
                localStorage.setItem("countryID", selectedCountryID);
                localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry))
                this.props.setCountryHandler(selectedCountryID)
                this.props.setLanguagesHandler(userPayload)
            }
        })
        .catch((err) => {
            console.log("error",err?.message)
            throw new Error(err?.message)
        })
    }

    render() {
        const Loader = () => {
            return (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            );
          };
      
        const {t,lang, countries, countriesloading,} = this.props;
        const {passwordError,profileInfo,loading} = this.state
        return (
            
            loading ? <Loader/> : <div className="container mt-3" style={{marginBottom:"7rem"}}>
            <h4 className='blue-color'>{t("Profile")}</h4>
            {/* <p>{t("Manage my profile")}</p> */}
            <div className="row">
                <div className="col-md-4 col-8">
                    <p className='text-muted mb-1'>{t("First Name")}</p>
                    <p>{profileInfo?.first_name}</p>
                    {/* <input type="text" value={profileInfo?.first_name} className='w-100' readOnly/> */}
                </div>
                <div className="col-md-4 col-8 mt-md-0 mt-3">
                    <p className='text-muted mb-1'>{t("Last Name")}</p>
                    <p>{profileInfo?.last_name}</p>
                    {/* <input type="text" value={profileInfo?.last_name} className='w-100' readOnly/> */}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-4 col-8">
                    <p className='text-muted mb-1'>{t("Email")}</p>
                    <p>{profileInfo?.email}</p>
                    {/* <input type="text" value={profileInfo?.email} className='w-100' readOnly/> */}
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-4 col-8">
                    <h4 className='blue-color'>{t("Preferences")}</h4>
                    {/* <input type="text" value={profileInfo?.email} className='w-100' readOnly/> */}
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-8">
                    <div className=" my-3">
                          <label>{t("Select a Default Country")}</label>
                          <select
                            className="arrows dis border w-100"
                            value={this.state.selectedCountryID?this.state.selectedCountryID:profileInfo?.currency_id}
                            onChange={({ target: { value } }) => {
                            this.setUserCountryHandler(value)
                        }}
                        >
                       {!countriesloading &&
                            countries?.map(country => {
                                return <option style={{ backgroundColor: "#fff", color: "#000" }}
                                    key={country.id}
                                     value={country?.currencies?.[0]?.id}>{country.name} </option>
                            })}
                          </select>
                    </div>
                </div>
                <div className="col-md-4 col-8 mt-md-0 mt-3">
                <div className=" my-3">
                          <label>{t("Select a Preferred Language")}</label>
                          <select
                            className="arrows dis border w-100"
                            onChange={(e) =>  this.toogleLang(e.target.value) }
                          >
                            {!countriesloading &&
                                this.state.languages && this.state.languages?.map(language => {
                                return <option style={{ backgroundColor: "#fff", color: "#000" }}
                                    key={language.code}
                                    selected={profileInfo?.language_code === language.code}
                                    value={language.code}>{language.name}
                                </option>

                            })}
                          </select>
                </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-4 col-8">
                   <button type='button' className='btn btn-block primary-btn rounded-0 text-center' onClick={this.handleUserPreference}>{this.state.loading ? <Loader /> : t("Add")}</button>
                </div>
            </div>
{/* 
            <h4 className='blue-color mt-5'>{t("Change Password")}</h4>
            <p>{t("Change password of your account")}</p>
                <div className="d-flex row flex-column">
                    <div className="col-md-4 col-8">
                        <p className='text-muted mb-1'>{t("New Password")}</p>
                        <input type="password" name="password" className='w-100' onChange={(e)=>this.setState({password:e.target.value})} onBlur={this.validatePassword}/>
                    </div>
                    <div className="col-md-4 col-8 mt-3">
                        <p className='text-muted mb-1'>{t("Retype Password")}</p>
                        <input type="password" name="confirmPassword" className='w-100' onChange={(e)=>this.setState({confirmPassword:e.target.value})} onBlur={this.validatePassword}/>
                        {
                            passwordError === "invalid" ? <p className="text-danger start-text">{t("Password must contain at least one (upper case and lower case letter, digit, special character) and min 8 characters")}</p>
                            : passwordError === "mismatch" ? <p className='text-danger'>{t("Password Mismatch")}</p> : null
                        }
                    </div>
                    <div className="col-10 mt-3">
                        <button className='btn blu-btn text-white' onClick={this.handleChangePassword}>{t("Change password")}</button>
                    </div>
                </div> */}
                <UserPreferenceSuccessModel
                show={this.state.show}
                userProfile={this.state.userProfile}
                 />
        </div>
            
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        valid: state.auth.valid,
        countries: state.countries.countries,
        countriesloading: state.countries.loading,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setLanguagesHandler: (payload) => dispatch(setLanguagesHandler(payload)),
        setCountryHandler: (payload) => dispatch(setCountryHandler(payload)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Myprofile))
