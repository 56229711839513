import React, { Component } from "react";
// import Breadcrumbs from "../shared/Breadcrumbs";
// import TAndConly from "../shared/T&Conly";
// import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import secretKeyGenerator from "../../utils/serectKeyGenerator";
import axios from "axios";
// import { currancyToPoints } from "../../utils/pointsConversion";
// import { Link } from "react-router-dom";
// import Apple_Wallet from "../../assests/img/Apple_Wallet.png";
import i18next from "i18next";
import thumbLogo from "../../assests/img/header/thumbLogo.png";
import enfactem from "../../assests/img/header/enfactemlogo.png"
import facbookSvg from "../../assests/img/social-media/facebook.svg";
import twitterSvg from "../../assests/img/social-media/twitter.svg";
import instagramSvg from "../../assests/img/social-media/instagram.svg";
import globeSvg from "../../assests/img/social-media/globe.svg";
import phoneIcon from "../../assests/img/Footer/telephone.svg";
import { REACT_APP_baseUrl } from 'utils/app-constants';
import { logoHandler } from "utils/Theme/Logo-handler";


class ViewDetailsSofGam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: {},
      loading:true,
    };
  }
  numberWithCom = (x) => {
    let num = +x;
    let y = num.toFixed(0);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  componentDidMount(){
    let urlLang = this.props.location?.search?.split("=")[1];
    if (urlLang !== this.props.lang && (urlLang === "en" || urlLang === "ar")) {
      i18next.changeLanguage(urlLang);
      this.props.setLang(urlLang);
      localStorage.setItem("lang", urlLang);
    }
      let payload = {
        "orders": {
            "id": +this.props.match.params.id
        }
    }
    let token = localStorage.getItem('userToken')
      axios.post(`${REACT_APP_baseUrl}/orders/get_topup_order_details`,payload,
      { 
        headers:{
            "Authorization":`Bearer ${token}`
        },
          params:{
              
          }
      }).then(response =>{
          this.setState({
            details:response.data?.data?.order,
            loading:false
          })
      })
  }
  // Detect Hyperlink form Trems
  detectURLs(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.split(urlRegex)
      .map(part => {
        if (part?.match(urlRegex)) {
          return <a href={part} key={part} target="_blank" rel="noreferrer" > {part} </a>;
        }
      else{
            return part;
      }
      });
  } 
  render() {
    let socialMediaLinks = [
      { id: "phone", url: "tel:920002470", imgSrc: phoneIcon },
      {
        id: "globe",
        url: "https://www.riyadbank.com/ar/personal-banking/hassad-rewards-program",
        imgSrc: globeSvg,
      },
      {
        id: "facebook",
        url: "https://ar-ar.facebook.com/RiyadBank/",
        imgSrc: facbookSvg,
      },
      {
        id: "twitter",
        url: "https://twitter.com/riyadbank?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
        imgSrc: twitterSvg,
      },
      {
        id: "instagram",
        url: "http://instagram.com/riyadbank",
        imgSrc: instagramSvg,
      },
    ];
    const card = this.state.details;
    const { t } = this.props;
    return (
      <>
        {this.state.loading ? (
          <section>
            <div class="d-flex justify-content-center pt-4 pb-3 mt-4 mb-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </section>
        ) : (
          <section>
            {/* <Breadcrumbs crumbs={breadcrumbs}/> */}

            {/* !this.props.loading && */}
            <header className="viewDetails py-1 pb-2">
              <img
                src={logoHandler()}
                style={{ width: "150px" }}
                class=""
                alt="logo"
                srcSet=""
              />
            </header>
            <div className="p-md-3 py-3 d-flex flex-md-row flex-column justify-content-center">
              <div className="p-0 d-md-block  text-center">
                
                  <div className="mx-5">
                    {" "}
                    <p style={{ color: "#747c91" }}>
                      {" "}
                      {this.props.lang !== "ar"
                        ? `Please find below your ${card?.brand_name}`
                        : `برصيد ${card?.brand_name_arabic} يمكنك استخدام بطاقة الهدايا الخاصة بك الموجودة أدناه من`}{" "}
                    </p>
                    {/* {this.numberWithCommas(currancyToPoints(giftcard_value * quantity))} */}
                  </div>

                <div className="pt-3 pb-1">
                  <img
                    style={{ width: "250px" }}
                    className="img-fluid p-0 mx-auto d-block"
                    src={card?.images?.medium_rectangle}
                    alt=""
                  />
                  <div className="d-flex mx-2">
                  {card?.mac_url && (
                      <>
                        <div className="pt-3 mx-2">
                          <button className="border-0 bg-color-skyblue p-2">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="nohover text-white text-decoration-none"
                              href={card?.mac_url}
                            >
                              {t("If you're Mac user, please click here")}
                            </a>
                          </button>
                        </div>
                      </>
                    )}

                    {card?.pc_url && (
                      <>
                        <div className="pt-3">
                          <button className="border-0 bg-color-skyblue p-2">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="nohover text-white text-decoration-none"
                              href={card?.pc_url}
                            >
                              {t("If you're Windows user, please click here")}
                            </a>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  
                  
                </div>
                
                
              </div>
            </div>
            <div className="p-2">
              <h4 className=" fs-5 mb-2 color-blue px-3"> {t("Redemption Instructions")}</h4>
              <div className="px-3 py-3 lanSpecific d-flex flex-column" style={{ textAlign:'left' }}>
                  {card?.instructions &&
                    
                    card?.instructions?.split("\n")?.map((term) => <div className='lanSpecific'>{this.detectURLs(term)}</div>)
                    }
                </div>
            </div>
            <footer className="text-center bg-color-blue">
              <div className="d-flex justify-content-center text-center p-4">
                {socialMediaLinks.map((link) => (
                  <div className="px-2" key={link.id}>
                    <a href={link.url} target="_blank" rel="noreferrer">
                      <img
                        className="img-fluid"
                        style={{ height: "2.0rem" }}
                        src={link.imgSrc}
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="p-4">
                {`${t("© 2022  ™ All Rights Reserved")}`}
              </div>
            </footer>
          </section>
        )}
        {/* } */}
      </>
    );
  }
}

export default withRouter(withTranslation()(ViewDetailsSofGam));
