import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import secretKeyGenerator from '../../utils/serectKeyGenerator';
import ProcessingModal from '../shared/Modals/ProcessingModal';
import {fetchOrderDetailsForExperience, removeOrder} from '../../Redux/order/orderActions'
import SuccessfullMessageModal from '../shared/Modals/SuccessfulMessageModal';
import { currancyToPoints } from '../../utils/pointsConversion';
import { withTranslation } from 'react-i18next';
import FailMessageModal from '../shared/Modals/FailMessageModal';
import { REACT_APP_baseUrl } from 'utils/app-constants';


class ExperienceSummaryPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            totalItem:1,
            orderDetails:{},
            pending:true,
            paymentCaptured:false,
            orderProcessed:false,
            orderStatus:'PENDING',
            paymentFail:false,
            errorMsg:" ",
            order_total:"",
            hassad_points:null,
            amount_paid_in_sar:null
         }
    }
    capturePayment(){
        const payload =  
        {
            "payment": {
                "orderid": this.props.orderDetails?.orderid,
                "type": "EXPERIENCES"
            }
        }
        let token = localStorage.getItem('userToken')

        axios.post(`${REACT_APP_baseUrl}/payments/capture_payment`,
        payload,
        {
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                
            }
        }).then(response=>{
            if(response.data?.code === 200 && response.data?.data?.order?.order_status === "PENDING"){
                    this.processOrder()
                }
              else if(response.data?.data?.order?.order_status === "CANCELED"){
                 this.setState({
                     pending:false,
                     paymentFail:true,
                     errorMsg:response.data?.message
                 })
             }
                else{
                this.setState({
                    pending:false,
                    paymentFail:true,
                    errorMsg:response.data?.message
                
                })
            }
        })
        .catch(error => {
            this.setState({pending:false,
               paymentFail:true,
               errorMsg:"Something went wrong!! Please try again!!"
            })
        })
    }
    componentDidMount(){

        // if(this.props.history.action == "POP" && this.props.location.pathname.startsWith("/payment/experience-orders-summary")){
        //     window.location.href = `/`
        //   }

        const payload = { 
            "experience_orders":{
                        "id": this.props.match.params.id
                    }
        }
        this.props.fetchOrderDetailsForExperience(payload)
    }
    componentDidUpdate(){
        if(!this.props.orderDetailsLoading && !this.state.paymentCaptured && !this.props.errorFlag && this.state.pending){
            if(Number(this.props.orderDetails?.payable_amount)===0){
                this.setState({
                    paymentCaptured:true,
                    orderProcessed:true
                })
                this.processOrder()
            }
            else if(this.props.orderDetails?.order_status === "PENDING"
            && this.props.orderDetails?.payment_method === "credit_debit_cards"
            ){
                this.setState({
                    paymentCaptured:true,
                    orderProcessed:true
                })
                this.processOrder(true)
            }
            else if(this.props.orderDetails?.order_status === "FULLFILLED" || this.props.orderDetails?.order_status === "CONFIRMED" || this.props.orderDetails?.order_status === "PLACED" || this.props.orderDetails?.order_status === "AWAITING_CONFIRMATION"){
                this.setState({pending:false,orderStatus:this.props.orderDetails?.order_status})
            }
            else if(this.props.orderDetails?.order_status === "CANCELED"){
                this.setState({pending:false,
                    paymentFail:true,
                    errorMsg:"Sorry, your payment was unsuccessful. Please try again later."})
            }
            else if(!this.props.orderDetailsLoading && !this.state.orderProcessed){
                this.setState({orderProcessed:true})
                this.processOrder()
            }
            else{
                this.setState({pending:false,
                    paymentFail:true
                })
            }
        }
        else if(this.props.errorFlag && this.state.pending){
            this.setState({pending:false,
                paymentFail:true,
                errorMsg:this.props.getErrorMsg
            })
        }
    }
    componentWillUnmount(){
        this.props.removeOrder()
    }

    numberWithCommas = (x) => {
        let num = +x;
        let y = num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    processOrder=(flag)=>{
        let payload =  {}

        let apiURL
        if(flag){
            payload =  {
                "orderid": this.props.orderDetails?.orderid
            }
            apiURL = "payments/process_order_after_redirect" 
        }else {
            payload = {
                "orders": {
                    "orderid": this.props.orderDetails?.orderid
                }
            }
            apiURL = "experience_orders/process_order"
        }
        let token = localStorage.getItem('userToken')
        axios.post(`${REACT_APP_baseUrl}/${apiURL}`,
        payload,
        {
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                
            }
        }).then(response=>{
            if(response.data.code === 200){
                const orderStatus = response.data?.data?.order?.order_status
                if(orderStatus === "CANCELED"){
                    this.setState({
                        pending:false,
                        paymentFail:true,
                        errorMsg:response.data?.message
                    })
                }
                else if(orderStatus === "FULLFILLED" || orderStatus === "CONFIRMED" || orderStatus === "PLACED" || orderStatus === 'AWAITING_CONFIRMATION'){
                    this.setState({
                        pending:false,
                        errorMsg:response.data.message,
                        order_total:response.data?.data?.order?.order_total,
                        hassad_points:response.data?.data?.order?.hassad_points,
                        amount_paid_in_sar:response.data?.data?.order?.amount_paid_in_sar,
                        stripe_amount:response.data?.data?.order?.stripe_amount,
                        stripe_payment_currency:response.data?.data?.order?.stripe_payment_currency
                    })
                }
            }
            else{
                this.setState({
                    pending:false,
                    paymentFail:true,
                    errorMsg:response.data?.message
                })
            }
        })
        .catch(error => {
            this.setState({
            pending:false,
              paymentFail:true,
              errorMsg:"Something went wrong!! Please try again!!"
            })
        })
    }
    render() { 
        const {pending,paymentFail, errorMsg} = this.state;
        const {t}=this.props
        const order =this.props.orderDetails;
        return ( 
            <main>
                <header className="border px-md-5 px-2 py-2 border-bottom text-muted d-flex bg-light w-100 ">
                    <div className="d-flex align-items-center flex-shrink-1 " >
                        <span className="btn border-color-skyblue bg-color-skyblue rounded-0 mx-2  p-md-2 p-1"></span>
                        <div className="m-0 d-inline px-md-1 px-0 pb-1 fs-md-6 fs-7 fw-bold">{t("Order Confirmation")}</div>
                    </div>
                    <hr className="hr-line flex-grow-1 mx-1"/>
                    <div className="d-flex align-items-center flex-shrink-1 " >
                        <span className="btn border-color-skyblue bg-color-skyblue rounded-0 mx-2  p-md-2 p-1"></span>
                        <div className="m-0 d-inline px-md-1 px-0 pb-1 fs-md-6 fs-7 fw-bold">{t("Payment")}</div>
                    </div>
                    <hr className="hr-line flex-grow-1 mx-1"/>
                    <div className="d-flex align-items-center flex-shrink-1 " >
                        <span className="btn bg-color-skyblue rounded-0 mx-2 p-md-3 p-2" ></span>
                        <div className="m-0 d-inline px-md-1 px-0 pb-1 fs-md-6 fs-7 fw-bold">{t("Order Placed")}</div>
                    </div>
                </header>
                <section >
                    <SuccessfullMessageModal show={!pending && !paymentFail} lang={this.props.lang}/>
                    <FailMessageModal show = {!pending && paymentFail} lang={this.props.lang} Message={errorMsg}/>
                    <ProcessingModal show={pending} lang={this.props.lang}/>
                    {(!pending && !paymentFail )&&
                        <>
                            <div className="d-md-flex d-none align-items-center px-5 pt-5">
                            <svg height="100" width="100">
                                <circle cx="50" cy="50" r="35" stroke="#00c2ab" strokeWidth="2" fill="transparent" />
                                <line x1='30' y1='50' x2='40' y2="65" stroke="#00c2ab" strokeWidth="2"/>
                                <line x1='40' y1="65" x2='70' y2='40' stroke="#00c2ab" strokeWidth="2"/>
                            </svg>
                            <h5>{t("Your payment has been completed successfully!")}</h5>
                        </div>
                        <div className="d-md-none d-sm-flex align-items-center px-2 " style={{backgroundColor:'yellowgreen'}}>
                            <svg height="50" width="50">
                                <circle cx="25" cy="25" r="17" stroke="#000" strokeWidth="2" fill="transparent" />
                                <line x1='15' y1='25' x2='20' y2="32"  stroke="#000" strokeWidth="2"/>
                                <line x1='20' y1="32" x2='35' y2='20'  stroke="#000" strokeWidth="2"/>
                            </svg>
                            <h6>{t("Your payment has been completed successfully!")}</h6>
                        </div>
                        </>
                    }
                    {
                    !this.props.orderDetailsLoading && 
                    <div className=" px-md-5 px-3 pb-5">
                        <div className="py-md-0 py-3 mb-md-3 mb-0 headings fw-bold fs-md-2 fs-4">{t("Your Order Details")}:</div>
                            <div className="d-flex flex-md-row flex-column border w-100 p-md-4 p-2 align-items-md-start align-items-center">
                                <div>
                                    <img width="200" className="img-fluid border" src={order?.images?.medium_rectangle} alt="experience card"/>
                                </div>
                                <div className="text-black-50 px-md-5 px-0">
                                    <div className="product_title fs-md-3 fs-6 fw-md-bolder fw-bold">{ this.props.lang !== 'ar' ?order?.brand_name:order?.brand_name_arabic }</div>
                                    <div className="fs-md-5 fs-6 fw-bold">{this.props.lang !== 'ar' ?order?.brand_name:order?.brand_name_arabic }</div>
                                    {/* <div className="py-2 fw-bold">
                                        Model #: Pro 5500M
                                    </div>
                                    <div className="py-2 fw-bold d-flex justify-items-center">

                                        <img src={truckImg} alt='' className="px-1" style={{height:"1.5rem"}}/>
                                        Delivery to 
                                        <b className="color-skyblue px-1"> Riyad</b>
                                    </div> */}
                                    <div className="py-3 fs-md-5 fs-6">
                                        <div className="py-2 d-flex align-items-center">
                                            <div className="px-3 py-2 bg-light-gray rounded-0 text-muted fw-bold">
                                                {t("Paid")}:<span className="text-dark fw-bolder ">{this.state.stripe_amount} {t(this.state.stripe_payment_currency)}</span>
                                            </div>
                                        </div>
                                       {    this.state.hassad_points ? <div className="py-2 fw-bold">
                                            <span>{t("RewardsBy Points")}: </span>
                                            <span className="text-dark fw-bolder"> {this.numberWithCommas(this.state.hassad_points)}  {t("Points")}</span>
                                        </div> : null }
                                        {
                                           this.state.amount_paid_in_sar ? <div className="py-2 fw-bold">
                                            <span>{t("Paid by Credit Card")}: </span>
                                            <span className="text-dark fw-bolder"> {this.numberWithCommas(currancyToPoints(this.state.amount_paid_in_sar))} {t("Points")} ({this.state.amount_paid_in_sar}{t("SAR")})</span>
                                        </div> : null
                                        }
                                    </div>  
                                </div>
                            </div>
                        </div>
                    }

                </section>
            </main>
         );
    }
}
const mapStateToProps = state => {
    return {
        authToken:state.auth.authToken,
        authLoading:state.auth.loading,
        orderDetails:state.order.orderDetails,
        orderDetailsLoading:state.order.loading,
        currancyCode:state.countries.convertionRate.currency_code,
        errorFlag:state.order.errorFlag,
        getErrorMsg:state.order.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrderDetailsForExperience: (payload) => dispatch(fetchOrderDetailsForExperience(payload)),
        removeOrder:()=>dispatch(removeOrder)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ExperienceSummaryPage));