import axios from "axios"
import { FETCH_POINTEXCHANGE_FAILURE, FETCH_POINTEXCHANGE_REQUEST, FETCH_POINTEXCHANGE_SUCCESS } from "./pointExchangeTypes"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { REACT_APP_baseUrl } from 'utils/app-constants';

export const fetchPointExchangeRequest = () => {
    return {
        type:FETCH_POINTEXCHANGE_REQUEST,
    }
}

export const fetchPointExchangeSuccess = data => {
    return {
        type:FETCH_POINTEXCHANGE_SUCCESS,
        payload:data
    }
}

export const fetchPointExchangeFailure = error =>{
    return {
        type:FETCH_POINTEXCHANGE_FAILURE,
        payload:error
    }
}

// const cacheHeaders = () => {
//     return {
//         headers: {
//             'Content-Type': 'application/json',
//             'Cache-Control': 'no-cache, no-store, must-revalidate',
//             'Expires': '0'
//         }
//     }
// }

export const fetchPointExchange = (payload) => {
    return (dispatch)=>{
        dispatch({type:FETCH_POINTEXCHANGE_REQUEST})
        dispatch(fetchPointExchangeRequest);
        axios.post( `${REACT_APP_baseUrl}/brands/get_brands`,
        payload,
        {
            params:{
                
            }
        })
            .then(response  => {
                const pointExchangeCards = []
                let categories = response.data.data.categories;
                categories.forEach(item => {
                    item.brands.forEach(card => {
                        if(card.point_exchange_flag) {
                            pointExchangeCards.push(card)
                        }
                    });
                })
                
              dispatch(fetchPointExchangeSuccess(pointExchangeCards))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchPointExchangeFailure("failed"))
            })
    }
}
