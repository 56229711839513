import axios from "axios"
import { ALL_CATEGORIES, CATEGORY_EXPERIENCES, CATEGORY_GIFTCARDS,CATEGORY_MERCHANDISE,CATEGORY_OFFERS,FETCH_DETAILS_FAILURE, FETCH_DETAILS_REQUEST, FETCH_RECORD_REQUEST, HASSAD_MALL_CATEGORIES, RECORD_BY_CATEGORIES } from "./categoriesTypes"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { REACT_APP_baseUrl } from 'utils/app-constants';


export const fetchDetailsRequest = () => {
    return {
        type:FETCH_DETAILS_REQUEST,
    }
}


export const fetchDetailsFailure = error =>{
    return {
        type:FETCH_DETAILS_FAILURE,
        payload:error
    }
}



export const fetchRecordByCategories = (payload) => {
    return (dispatch)=>{
        dispatch({type: FETCH_RECORD_REQUEST})
        axios.post(`${REACT_APP_baseUrl}/categories/category_details`,
            payload,
            {
                params:{
                    
                }
            })
            .then(response  => {
                let categories = response.data.data;
                return dispatch({
                    type: RECORD_BY_CATEGORIES,
                    payload:{
                       recordByCategories:categories,
                       recordLoading:true
                    }
                })
            })
            .catch(error => {
                dispatch(fetchDetailsFailure("failed"))
            })
    }
}

export const fetchCategoriesList = () => {
    
    return (dispatch)=>{
        axios.get(`${REACT_APP_baseUrl}/categories/get_categories`)
            .then(response  => {
                let categories = response.data.data.categories;
                let filteredCategories = categories.filter(category => category.name !== "E-Magazine" && category.name !== "Fashion" && category.name !== 'Mobile Recharge')
                return dispatch({
                    type: ALL_CATEGORIES,
                    payload:{
                       allCategories:filteredCategories
                    }
                })
            })
            .catch(error => {
                dispatch(fetchDetailsFailure("failed"))
            })
    }
}



export const giftCardCategory = (categoryName,data,countrySelected) => {
    
    return (dispatch)=>{
        dispatch(fetchDetailsRequest);
        const payload ={
            "brands": {
                "currency": countrySelected
            }
        }
        if(data){
            const giftCards = []
            let categoryFilter = data.filter((category) => category.name === categoryName)
            if(categoryFilter?.length === 0){}
            else {categoryFilter[0].brands.forEach(card => {
                giftCards.push(card)
            });}
            return dispatch({
                type: CATEGORY_GIFTCARDS,
                payload:{
                    cards : giftCards  
                }
            })  
        }
        else{
            axios.post(`${REACT_APP_baseUrl}/brands/get_brands`,
            payload,
            {
                params:{
                    
                }
            })
            .then(response  => {
                const giftCards = []
                let categories = response.data.data.categories;
            
                let categoryFilter = categories.filter((category) => category.name === categoryName)
                
                if(categoryFilter?.length === 0){}
                else {categoryFilter[0].brands.forEach(card => {
                    giftCards.push(card)
                });}
                return dispatch({
                    type: CATEGORY_GIFTCARDS,
                    payload:{
                        giftCardCategory:categories,
                        cards : giftCards,
                        
                    }
                })
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchDetailsFailure("failed"))
            })
            
        }
    }
}


export const fetchOffersCategory = (categoryName,data,countrySelected) => {
    const payload ={
        "offer_campaign": {
            "currency": countrySelected
        }
    } 
    return (dispatch)=>{
        dispatch(fetchDetailsRequest);
        if(data){
                let offerCards=[]
                let items = data.filter(item => item.name === categoryName)
                if(items?.length === 0){}
                else {items[0].offers.forEach(card => {
                    offerCards.push(card)
                });}
                return dispatch({
                    type: CATEGORY_OFFERS,
                    payload:{
                        cards : offerCards,   
                    }
                })
        }
        else{
            axios.post(`${REACT_APP_baseUrl}/offer_campaigns/get_offers_by_currency`,
            payload,
            {
                params:{
                    
                }
            })
            .then(response  => {
               
                let offerCards=[]
                let items = response.data.data.categories.filter(item => item.name === categoryName)
                if(items?.length === 0){}
                else {items[0].offers.forEach(card => {
                    offerCards.push(card)
                });}
                return dispatch({
                    type: CATEGORY_OFFERS,
                    payload:{
                        offerCardCategory: response.data.data.categories,
                        cards : offerCards,
                        
                    }
                })
                
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchDetailsFailure("failed"))
            })
        }

    }
}


export const fetchExperiencesCategory = (categoryName,data,countrySelected) => {
    const payload ={
        "experiences": {
            "currency": countrySelected
        }
    }
    return (dispatch)=>{
        dispatch(fetchDetailsRequest);
        if(data){
                let experiencesCards=[]
                let items = data.filter(item => item.name === categoryName)
                if(items?.length === 0){}
                else {items[0].experiences.forEach(card => {
                    experiencesCards.push(card)
                });}
                return dispatch({
                    type: CATEGORY_EXPERIENCES,
                    payload:{
                        cards : experiencesCards,   
                    }
                })
        }
        else{
            axios.post(`${REACT_APP_baseUrl}/experiences/get_experiences`,
            payload,
            {
                params:{
                    
                }
            })
            .then(response  => {
               
                let experiencesCards=[]
                let items = response.data.data.categories.filter(item => item.name === categoryName)
                if(items?.length === 0){}
                else {items[0].experiences.forEach(card => {
                    experiencesCards.push(card)
                });}
                return dispatch({
                    type: CATEGORY_EXPERIENCES,
                    payload:{
                        experiencesCardsCategory: response.data.data.categories,
                        cards : experiencesCards, 
                    }
                })
                
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchDetailsFailure("failed"))
            })
        }

    }
}


export const fetchMerchandiseCategory = (categoryName,data,countrySelected) => {
   
    const payload ={
        "merchant_product": {
            "currency": countrySelected
        }
    }
    return (dispatch)=>{
        dispatch(fetchDetailsRequest);
        if(data){
                let merchandiseCards=[]
                let items = data.filter(item => item.name === categoryName)
                if(items?.length === 0){}
                else {items[0].products.forEach(card => {
                    merchandiseCards.push(card)
                });}
                return dispatch({
                    type: CATEGORY_MERCHANDISE,
                    payload:{
                        cards : merchandiseCards,   
                    }
                })
        }
        else{
           
            axios.post(`${REACT_APP_baseUrl}/merchant_products/get_products`,
            payload,
            {
                params:{
                    
                }
            })
            .then(response  => {
               
                let merchandiseCards=[]
                let items = response.data.data.categories.filter(item => item.name === categoryName)
               
                if(items?.length === 0){}
                else {items[0].products.forEach(card => {
                    merchandiseCards.push(card)
                });}
                return dispatch({
                    type: CATEGORY_MERCHANDISE,
                    payload:{
                        merchandiseCardsCategory: response.data.data.categories,
                        cards : merchandiseCards, 
                    }
                })
                
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchDetailsFailure("failed"))
            })
        }

    }
}

export const hassadMallCategory = () => {
    return (dispatch)=>{
        dispatch({type: FETCH_DETAILS_REQUEST})
        axios.get(`${REACT_APP_baseUrl}/platform_module_images`)
            .then(response  => {
                let categories = response.data.data.module_images.sort((a,b) => a.sort_order - b.sort_order);
                return dispatch({
                    type: HASSAD_MALL_CATEGORIES,
                    payload:{
                      categories: categories
                    }
                })
            })
            .catch(error => {
                dispatch(fetchDetailsFailure("failed"))
            })
    }
}