import axios from "axios"
import {RESET_REQUEST,RESET_SUCCESS,RESET_FAILURE,RESET_CLEAR} from "./ResetTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';
import secretKeyGenerator from "../../utils/serectKeyGenerator"

export const fetchResetRequest = () =>{
    return{
        type:RESET_REQUEST
    }
}

export const fetchResetSuccess = (data) =>{
    return{
        type:RESET_SUCCESS,
        payload:data
    }
}

export const ResetClear = () =>{
    return{
        type: RESET_CLEAR
    }
}

export const PassRest = (oldpass,newpass,confirmPass,token) => {
    const ResetPayload = {
        change_password:{
            current_password: oldpass,
            password: newpass,
	        password_confirmation: confirmPass
        }
    }
    return (dispatch)=>{
        dispatch(fetchResetRequest())
        axios.put(`${REACT_APP_baseUrl}/accounts/passwords/change_password`,
        ResetPayload,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
            params:{
            }
        }
        ).then(response  => {
                dispatch(fetchResetSuccess(response.data.message))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg);
                dispatch({type:RESET_FAILURE,payload:errorMsg})
            })
    }
}

