import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { CATEGORY_BRANDS, FETCH_BRAND_FAILURE, FETCH_BRAND_REQUEST, FETCH_BRAND_SUCCESS, 
    GET_BRAND_FAILURE, GET_BRAND_REQUEST, GET_BRAND_SUCCESS } from "./brandTypes"
import {cacheHeaders} from "../../utils/pointsConversion"
import { REACT_APP_baseUrl, REACT_APP_baseUrl_V2, REACT_APP_baseUrl_V3 } from "utils/app-constants"
import store from "Redux/store"

const API_URL_V3 = `${REACT_APP_baseUrl_V2}`

export const fetchBrandRequest = () => {
    return {
        type:FETCH_BRAND_REQUEST,
    }
}

export const fetchBrandSuccess = data => {
    return {
        type:FETCH_BRAND_SUCCESS,
        payload:data
    }
}

export const fetchBrandFailure = error =>{
    return {
        type:FETCH_BRAND_FAILURE,
        payload:error
    }
}

export const fetchBrand = (payload) => {
    return (dispatch)=>{
        dispatch({type: FETCH_BRAND_REQUEST})
        dispatch(fetchBrandRequest);

        axios.post(`${API_URL_V3}/brands/get_brand_details`,
            payload,
            {
                params: {
                }
            })
            .then(response  => {
                if(response.data.code === 200){

                    const brand = response.data?.data?.brand
                    dispatch(fetchBrandSuccess(brand))
                }
                else{

                    const errorMsg = response.data?.message
                    dispatch(fetchBrandFailure(errorMsg))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchBrandFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const getBrandRequest = () => {
    return {
        type:GET_BRAND_REQUEST,
    }
}

export const getBrandSuccess = (data) => {
    return {
        type:GET_BRAND_SUCCESS,
        payload:data
    }
}

export const getBrandFailure = error =>{
    return {
        type:GET_BRAND_FAILURE,
        payload:error
    }
}

export const brandsByCategory = (data) =>{
    return{
        type : CATEGORY_BRANDS,
        payload : data
    }
}

export const getCategoriesByModule = (payload) =>{
    axios.get(`${REACT_APP_baseUrl}/categories/get_categories`,{
        params: payload
    }).then(res =>{
        if(res.status === 200){
            store?.dispatch(brandsByCategory(res?.data?.data?.categories))
        }
    }).catch(err => console.log("categoryError", err))
}

export const getBrandData = (params, isReset, pageNumber) => {
    return (dispatch) => {
        dispatch({ type: GET_BRAND_REQUEST });

        axios.get(`${API_URL_V3}/brands/get_brands`, {
            params
        })
            .then(response => {
                if (response.data.code === 200) {
                    const brandData = response.data.data?.brands;

                    let payload = {
                        brandData,
                        total: response.data?.data?.recordsTotal,
                        isReset,
                        pageNumber
                    }

                    dispatch(getBrandSuccess(payload));
                } else {
                    const errorMsg = response.data?.message;
                    dispatch(getBrandFailure(errorMsg));
                }
            })
            .catch(error => {
                const errorMsg = error;
                console.log(errorMsg);
                dispatch(getBrandFailure("Something went wrong!! Please try again!!"));
            });
    };
};
