import React, { Component } from 'react';
import Breadcrumbs from '../shared/Breadcrumbs';
import BuyNowCard from '../shared/BuyNowCard';
// import { currancyToPoints } from '../../utils/pointsConversion';
import { connect } from 'react-redux';
import InputFeild from '../PaymentFlow/InputFeild';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import secretKeyGenerator from '../../utils/serectKeyGenerator';
import {fetchCountiriesTopUp, fetchServices} from "../../Redux/mobileTopUp/mobileActions";
import {fetchPhoneFormat} from "../../Redux/CountryCode/CountryCodeActions";
import MobileTopUpModal from '../shared/Modals/MobileTopUpModal';
import OperatorModal from '../shared/Modals/OperatorModal';
import TAndConly from '../shared/T&Conly';
import { checkTokenValidity } from '../../Redux/auth/authActions';
import { REACT_APP_baseUrl } from 'utils/app-constants';
import { currancyToPoints } from 'utils/pointsConversion';
import WarningModal from 'components/shared/Modals/WarningModal';

class MobileTopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topUpAmount:null,
            card: { id: null },
            serviceChargePoint:null,
            serviceCharge:null,
            selectedRate: null,
            confirmOrder:false,
            orders:{},
            payload:{},
            quantity:1,
            isLoaded: false,
            operatorCode:null,
            countrycode : undefined,
            formFeilds:{
                country:{id:"country",title:"Country",type:"dropdown",value:"",required:true,tabIndex:"1",readOnly:false,ariaLabel:"select your country",errorMessage:"",options:[]},
                mobileOperator:{id:'mobileOperator',title:'Mobile Operator',type:'dropdown',value:'',required:true,tabIndex:"2",readOnly:false,ariaLabel:"select your mobile Operator",errorMessage:"",options:[]},
            },
            operatorlist:[],
            amountValues:[],
            msg : " ",
            msgFlag : false,
            currentCountry:this.props.selectedCountry,
            currentOperator:"",
            currentOpId: undefined,
            phNumber: undefined,
            type: "" ,
            highlightCard:undefined,
            phoneNumber:null,
            topUpAmountLoad:true,
            topUpProduct: false,
            point_conversion_rate:0.1,
            currency_exchange_rate:10,
            hideoperator:false,
            currency : null,
            cardPayment:false,
            selected_currency_code:null
        }
    }

    topupServiceAPI(){
        const payload = {
            "brands": {
                "currency": this.props.countrySelected,
                "program_id": 1,
                "id":this.props.services.id
            }
        }
        this.props.fetchServices(payload);
    }

    componentDidMount(){
        localStorage.removeItem("type");
        localStorage.setItem("type", "mobileTopup");
        this.props.checkTokenValidity() 
        this.topupServiceAPI()
    }

    componentDidUpdate(prevProps,prevState){
        if(!this.props.mobileCountryLoading && this.state.formFeilds.country.options.length===0){
            let updatedFormFeilds = {...this.state.formFeilds}
            updatedFormFeilds.country.options = this.props.mobileCountries?.map(val=>val.name)
            if(this.state.formFeilds.country.options.length>0){
                this.setState({
                    formFeilds : updatedFormFeilds
                })

                if(!this.props.mobileCountryLoading && this.props.mobileCountries && this.props.mobileCountries.length>0){
                    let countryCode = this.props.mobileCountries.filter(e => e.name === this.state.currentCountry)[0]
                    this.countryInList(countryCode?.iso_code,this.state.currentCountry);
                    this.setState({countrycode:countryCode?.iso_code})
                }
            }
        }
        if( !this.props.mobileCountryLoading &&  this.props.services?.length=== 0){
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/mobileTopUp'
            })
        }
        if(this.props.countrySelected !== prevProps.countrySelected){
            let updatedFormFeilds = {...this.state.formFeilds}
            updatedFormFeilds.country.options = [];
            let selectedCountry = this.props.selectedCountry;
            this.setState({topUpAmountLoad:true,formFeilds:updatedFormFeilds,currentCountry:selectedCountry})
            this.topupServiceAPI()
        }
    }

    countryInList = (countrycode, countryName) => {
        const countryload = {
            "countries": {
                "country_name": countryName
            }
        }

        axios.post(`${REACT_APP_baseUrl}/countries/get_country_details`,
            countryload,
            {
                params: {
                    
                }
            }).then(response => {
                if (response.data?.code === 200) {
                    let point_conversion_rate = response.data?.data.point_conversion_rate
                    let currency_exchange_rate = response.data?.data.currency_exchange_rate
                    let selected_currency_code = response.data?.data.currency_code
                    this.setState({
                        point_conversion_rate: point_conversion_rate,
                        currency_exchange_rate: currency_exchange_rate,
                        selected_currency_code: selected_currency_code,
                        hideoperator:false
                    })
                    this.props.fetchPhoneFormat(countryload)
                    this.getOperaterList(countrycode, countryName)
                }
                else {
                    let updatedFormFeilds = {...this.state.formFeilds}
                    updatedFormFeilds.mobileOperator.options = []
                        this.setState({
                            msg : response.data?.message,
                            formFeilds : updatedFormFeilds,
                            msgFlag : true,
                            topUpProduct:false,
                            hideoperator:true,
                            amountValues : [],
                            currentOpId : undefined,
                            data : [],
                            highlightCard : undefined,
                            selectedRate : null
                        })
                }
            })

    }

    selectValue=(val,feild)=>{
        let updatedFormFeilds = {...this.state.formFeilds}
        updatedFormFeilds[feild].value = val
        updatedFormFeilds[feild].errorMessage = ""
        this.setState({
            formFeilds:updatedFormFeilds,
            topUpProduct:true
        })
        if(feild === "country"){
            const isoCode = this.props.mobileCountries.find(value => (value.name === val )).iso_code
            this.setState({
                countrycode : isoCode,
                currentCountry:val
                    // || value.country_name_arabic === val
            })
            this.countryInList(isoCode,val)
            
        }
        if(feild === "mobileOperator"){
            let operatorId = this.state.operatorlist?.find(opId => opId.name === val)
            this.setState({
                currentOperator : operatorId?.name,
                currentOpId : undefined
            })
            this.getRangelist(operatorId.id)
        }
    }

    getOperaterList = (countrycode,countryName) => {
        const payload = {
            "countries":{
                "country_code": countrycode
            }
        }
            axios.post(`${REACT_APP_baseUrl}/countries/list_of_topup_operators`,
            payload,
            {
                params:{
                    
                }
            }).then(response=>{
                const code = response.data.data.operators
                let updatedFormFeilds = {...this.state.formFeilds}
                updatedFormFeilds.mobileOperator.options = code?.map(val=>val.name)
                updatedFormFeilds.country.value = code[0]?.country_name
                this.setState({
                    formFeilds : updatedFormFeilds,
                    operatorlist: code,
                    currentOperator : code[0]?.name,
                    currentOpId: undefined
                })  
                 const OpID = code?.find((val,index) => index === 0 && val).id
                this.getRangelist(OpID)
            })

    }

    getRangelist = (operatorId) =>{
        const payload = {
            "countries":{
                "operator_id": operatorId
            }
        }
        axios.post(`${REACT_APP_baseUrl}/countries/list_of_ranged_topup_products`,
            payload,
            {
                params:{
                    
                }
            }).then(response=>{
                if( response.data.code && response.data.code === 200){
                    if(response.data.data.products[0].message === "Product is not available in your account"){
                        this.setState({
                            amountValues : [],
                            highlightCard : undefined,
                            currentOpId : undefined,
                            data : [],
                            selectedRate : null
                        })
                        this.getFixedList(payload)
                    }
                    else{
                        this.setState({
                            amountValues : response.data?.data?.products[0]?.denomination,
                            type:"RANGED_VALUE_RECHARGE",
                            currentOpId : response.data?.data?.products[0]?.product_id,
                            data : response.data?.data?.products,
                            operatorCode : response.data?.data?.products[0]?.unit,
                            topUpProduct: false,
                            topUpAmountLoad:false
                        })
                        const denom = response.data?.data?.products[0]
                        this.setSelectedRate(denom?.denomination && denom?.denomination[0])
                }
            }
            else{
                this.setState({
                    amountValues : [],
                    highlightCard : undefined,
                    currentOpId : undefined,
                    selectedRate : null,
                    data:[]
                })
            }
            })

    }
    currencyToPoints = (currancy) => {
        let points = Math.round((currancy * this.state.currency_exchange_rate)/this.state.point_conversion_rate);
        if(isNaN(points)) return 0
        else return Math.ceil(points/ 10) * 10;
    }

    getFixedList = (payload) => {
        // const {msgFlag} = this.state
        axios.post(`${REACT_APP_baseUrl}/countries/list_of_fixed_topup_products`,
            payload,
            {
                params:{
                    
                }
            }).then(response=>{
                if( response.data.code && response.data.code === 200){
                    this.setState({
                        topUpAmountLoad:false,
                        topUpProduct: false
                    })
                    if(response.data.data.products[0].message === "Product is not available in your account"){
                        this.setState({
                            msg : " ",
                            msgFlag : false,
                            amountValues:[],
                            highlightCard : undefined,
                            currentOpId : undefined,
                            data : [],
                            selectedRate : null
                        })
                        this.getPinFixedList(payload)
                    }
                    else{
                        const fixedAmount = response.data.data.products?.map(val => val?.amount)
                        this.setState({
                                amountValues : fixedAmount,
                                type:"FIXED_VALUE_RECHARGE",
                                currentOpId : response.data?.data?.products[0]?.product_id,
                                operatorCode : response.data?.data?.products[0]?.unit,
                                data : response.data?.data?.products
                            })
                            this.setSelectedRate(response.data?.data?.products[0]?.amount)
                    }
                }
                else{
                    this.setState({
                        amountValues : [],
                        highlightCard : undefined,
                        selectedRate : null,
                        currentOpId : undefined,
                        data : []
                    })
                }
            })

    }

    getPinFixedList = (payload) => {
        const {msgFlag} = this.state
        axios.post(`${REACT_APP_baseUrl}/countries/list_of_pin_fixed_topup_products`,
            payload,
            {
                params:{
                    
                }
            }).then(response=>{
                if( response.data.code && response.data.code === 200){
                    this.setState({
                        topUpAmountLoad:false,
                        topUpProduct: false
                    })
                    if(response.data.data.products[0].message === "Product is not available in your account"){
                        this.setState({
                            msg : "The denomination is not available for the selected operator",
                            msgFlag : !msgFlag,
                            amountValues:[],
                            highlightCard : undefined,
                            currentOpId : undefined,
                            data : [],
                            selectedRate : null
                        })
                    }
                    else{
                        const fixedAmount = response.data.data.products?.map(val => val?.amount)
                        this.setState({
                                amountValues : fixedAmount,
                                type:"FIXED_VALUE_PIN_PURCHASE",
                                currentOpId : response.data?.data?.products[0]?.product_id,
                                operatorCode : response.data?.data?.products[0]?.unit,
                                data : response.data?.data?.products
                            })
                            this.setSelectedRate(response.data?.data?.products[0]?.amount)
                    }
                }
                else{
                    this.setState({
                        amountValues : [],
                        highlightCard : undefined,
                        selectedRate : null,
                        currentOpId : undefined,
                        data : [],
                        msg : " ",
                        msgFlag:false
                    })
                }
            })

    }

    orderSummary = (val,...rest) => {
        if (this.state.selectedRate === null ){
            let validTopUpAlert = document.getElementsByClassName('topUp-Alert')[0]
            validTopUpAlert.style.display = 'block'
            return  true
        }
        let number =  ""
        if(this.state.phoneNumber){
            number = this.state.phoneNumber
        }
       let numLength = number.split('').length
       let validNumberAlert = document.getElementsByClassName('mobAlert')[0]
       
       if(numLength< this.props.PhoneFormat.phone_min_length){
        validNumberAlert.style.display = 'block'
        let offsetTop = document.getElementById("mobileAlert").offsetTop;
        window.scroll({
          top:offsetTop-100,
          behavior:"smooth"
        })
        return true
       
       }
            let value = this.state.selectedRate.toFixed(2)
            let amount = value
            let amount_points = Number(value)
            let paymentType = rest[0]?.paymentType
        paymentType === 'creditcard' ? this.setState({cardPayment:true}) : this.setState({cardPayment:false})
            this.setState(prevState=>({
                confirmOrder:val,
                orders: {
                    "quantity":1,
                    "giftcard_value":amount,
                    "amount":amount,
                    "language_code":this.props.lang,
                },
                payload: {
                    "orders": {
                    "topup_destination": this.state.currentCountry,
                    "mobile_operator": this.state.currentOperator,
                    "mobile_number": `${this.props.PhoneFormat?.country_code}${this.state.phoneNumber}`,
                    "recharge_type": this.state.type,
                    "topup_value": this.state.highlightCard,
                    "hassad_points": paymentType === 'creditcard' ? 0 : amount_points <= Number(this.props.pointBalance) ? amount_points: Number(this.props.pointBalance),
                    "language_code": this.props.lang,
                    "currency": this.props.countrySelected,
                    "topup_product_id": this.state.currentOpId,
                    "use_hassad_points":paymentType === 'creditcard' ? false : this.props.pointBalance>0,
                    "topup_currency": this.state.operatorCode,
                    "amount": this.state.topUpAmount
                }
            }
            }))
    }
    
    setSelectedRate = ( val,id) => {
        console.log(val);
        let prodId = []
        if(this.state.type === "RANGED_VALUE_RECHARGE"){
            prodId = this.state.data[0]?.product_id
        }
        else if(this.state.type === "FIXED_VALUE_RECHARGE"){
            let ID = this.state.data?.filter(value => value.amount === val &&  value.product_id)
            prodId = ID[0]?.product_id
        }
        else if(this.state.type === "FIXED_VALUE_PIN_PURCHASE"){
            let ID = this.state.data?.filter(value => value.amount === val &&  value.product_id)
            prodId = ID[0]?.product_id
        }
        if((this.state.selected_currency_code === 'AED')){
            const convertedCurrency = (val * this.state.currency_exchange_rate) / this.state.point_conversion_rate
            const price = (convertedCurrency * 0.1) + convertedCurrency;
            const amount = (val * 0.1) + val
            this.setState({
                selectedRate : price,
                highlightCard:val,
                currentOpId : prodId,
                defaultPrice:val,
                topUpAmount:amount
            })
        } 
        else {
            const convertedCurrency = (val * this.state.currency_exchange_rate) / this.state.point_conversion_rate
            const price = (convertedCurrency * 0.2) + convertedCurrency;
            const amount = (val * 0.2) + val
            this.setState({
                selectedRate : price,
                highlightCard:val,
                currentOpId : prodId,
                defaultPrice:convertedCurrency,
                topUpAmount:amount
            })
        }
    }

    phoneNumber = (num) => {
            this.setState({
                phNumber : num
            })
    }
    setphoneNumber=(e,val)=>{
        this.setState({
            phoneNumber:val
        })
    }

    close = () => {
        this.setState({
            msgFlag : false
        })
    }
    numberWithCommas = (x) => {
        let num = +x;
        let y = num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        const { selectedRate,formFeilds,confirmOrder,orders, payload, highlightCard } = this.state
        const {t} = this.props
        const breadcrumbs = [
            { name: t("Home"), url: '/', active: true },
            { name: t("Mobile TopUp"), url: ``, active: false }
        ];
        return (
            <>
             {  this.state.topUpAmountLoad ? <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section> :
            <section>
                {this.state.msgFlag &&
                <OperatorModal show={this.state.msgFlag} lang={this.props.lang} msg={this.state.msg} close ={this.close}/>
                }
                <Breadcrumbs crumbs={breadcrumbs} />
                {
                    <div className="p-md-5 p-3 d-flex flex-md-row flex-column">
                        {
                                confirmOrder && <MobileTopUpModal lang={this.props.lang} payload={payload} orders={orders} card = {this.props.services && this.props.services[0]} show={confirmOrder} remove={this.orderSummary} cardPayment={this.state.cardPayment} pickedTopupAmount={highlightCard} pickedTopupCurrency={this.state.data?.[0]?.unit}  defaultPrice={this.state.defaultPrice} selected_currency_code={this.state.selected_currency_code}/>
                            }
                        <div className="col-3 p-0 d-md-block d-none">
                            <img
                                className="img-fluid mx-auto d-block w-100"
                                src={this.props.services && this.props.services[0]?.images.color?.small_rectangle}
                                alt={this.props.services && this.props.services[0]?.name}
                            />

                        </div>
                        <div className="flex-grow-1 px-md-5 px-0">
                            <div className="pb-md-3 pb-2">
                                <div className="fs-md-3 fs-5 fw-md-bold fw-bolder headings">
                                {t("Mobile TopUp")}
                                </div>
                            </div>
                            <div className='d-md-none d-block p-3'>
                            <img
                                className="img-fluid mx-auto d-block w-100"
                                src={this.props.services && this.props.services[0]?.images.color.small_rectangle}
                                alt={this.props.services && this.props.services[0]?.name}
                                
                            />
                            </div>
                            {/* <hr className="hr-line m-0 my-2"/> */}
                            {
                                    this.state.topUpProduct ?
                                    <div className="spinner">
                                                    <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                                     <div className="bounce3"></div>
                                                            </div>

                                :
                                <>
                            <div className="col-md-6 col-12 p-0 ">
                                {
                                    Object.values(formFeilds).map(f=>(
                                        <InputFeild data={f} key={f.id} onChange={this.selectValue}/>
                                    ))
                                }
                            </div>
                            <label className='d-flex align-items-center mt-2 pt-2 gap-1'>
                                <h6 className="fw-normal">{t("Select Top-Up Amount")}</h6>
                                <h6 className='fw-bold'>{(`(${t("in")} ${this.state.data?.[0]?.unit || ""}) `)}:</h6></label>
                            <div className="w-100 d-flex flex-column">
                                {/* <div className="fw-bold">
                                    Amount: <span className="text-dark fw-bold"> {this.numberWithCommas(selectedRate)} {this.state.countrycode}</span>
                                </div> */}
                                <div className="d-flex flex-wrap py-2">
                                    {
                                      this.state.amountValues &&  this.state.amountValues.map(rate => (
                                            <button style={{width:"78px"}} key={rate}
                                                className={`btn mr-3 px-4 rounded-0 gridRates no-outline ${highlightCard === rate ? 'selected-value' : 'text-muted card-rate'}`}
                                                onClick={() => this.setSelectedRate(rate)}>
                                                {rate}
                                            </button>

                                        ))
                                    }

                                </div>
                                <div style={{display:'none',color:"red"}} className=" topUp-Alert alert " role="alert">
                                   * {t("Please Select a TopUp Amount")}
                                    </div>
                            </div>
                            {
                               this.state.topUpProduct ?
                               false:
                               <>
                                {this.state.selectedRate && <div className="fs-6 fw-bold text-dark mt-2 mb-3">
                                {t("Amount inclusive of service charge")} : {this.numberWithCommas((selectedRate))} <small> {t("Points")}</small>
                            </div>}
                               </>
                           }
                            <label>{t("Please Enter Your Mobile Number")}</label>
                            <div className="d-flex col-md-6 col-12 p-0"> 
                                           {
                                               this.props.lang!=='ar'?
                                               <>
                                                <select className="btn border bg-light rounded-0 col-3 caret px-1" style={{textAlignLast:"center"}}>
                                                <option>
                                                {this.props.PhoneFormat && this.props.PhoneFormat.country_code}</option>
                                            </select>
                                            <input 
                                                className="col-9 py-2 rounded-0 border text-dark"
                                                type="tel" 
                                                minLength={this.props.PhoneFormat.phone_min_length}
                                                maxLength={this.props.PhoneFormat.phone_max_length}
                                                pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                                name="number" 
                                                onChange={(e)=>this.setphoneNumber(e,e.target.value)}
                                                value={this.state.phoneNumber} />
                                               </>:
                                               <>
                                            <input 
                                                className="col-9 py-2 rounded-0 border text-dark"
                                                type="tel" 
                                                minLength={this.props.PhoneFormat.phone_min_length}
                                                maxLength={this.props.PhoneFormat.phone_max_length}
                                                pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                                name="number" 
                                                onChange={(e)=>this.setphoneNumber(e,e.target.value)}
                                                value={this.state.phoneNumber} />
                                                  <select className="btn border bg-light rounded-0 col-3 caret px-1" style={{textAlignLast:"center"}}>
                                                <option>
                                                {this.props.PhoneFormat && this.props.PhoneFormat.country_code}</option>
                                            </select>
                                               </>
                                           }
                                            {/* <div>{t("Format")}: +{this.props.PhoneFormat && this.props.PhoneFormat.country_code} X XXX XXXX</div> */}
                                        </div>
                                        <div style={{display:'none',color:"red"}} className=" mobAlert alert " role="alert" id="mobileAlert">
                                   * {t("Enter a valid mobile number")}
                                    </div>
                            </>
                            }
                           <div className='py-3 d-block d-md-none'>
                        
                            <TAndConly  termsConds={this.props.terms}  lang={this.props.lang}/>
                           </div>
                        </div>
                        <BuyNowCard onSubmit={this.orderSummary} quantity={false} isGift={false}
                         price={true}
                         GoalItem={false}
                         toogleLang={this.props.toogleLang}
                        />
                    </div>

                }
                 <div className='d-md-block d-none pb-4 px-5 col-9'>             
                            <TAndConly  termsConds={this.props.terms} lang={this.props.lang}/>
                           </div>
            </section >
    }
    </>
        );
    }
}

const mapStateToProps = state => {
    return {
        mobileCountries:state.mobileCountry.mobileCountries,
        services:state.mobileCountry.services,
        mobileCountryLoading:state.mobileCountry.loading,
        countrySelected:state.countries.countryId,
        PhoneFormat:state.PhoneFormat.PhoneFormat,
        pointBalance:state.point.pointBalance,
        fetchError:state.mobileCountry.error,
        terms: state.mobileCountry.terms,
        countryList: state.countries.countries,
        currancyCode:state.countries.convertionRate.currency_code,
        selectedCountry:state?.countries?.selectedCountry?.countryName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCountiriesTopUp: () => dispatch(fetchCountiriesTopUp()),
        fetchServices : (payload) => dispatch(fetchServices(payload)),
        fetchPhoneFormat : (payload) => dispatch(fetchPhoneFormat(payload)),
        checkTokenValidity : () => dispatch(checkTokenValidity())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MobileTopUp));