import React, { Component } from 'react';
import Breadcrumbs from '../shared/Breadcrumbs';
import BuyNowCard from '../shared/BuyNowCard';
import TAndConly from '../shared/T&Conly';
import OrderConfirmationModal from '../shared/Modals/OrderConfirmationModal';
import { connect } from 'react-redux';
import {currancyToPoints} from '../../utils/pointsConversion';
import {removeOrder} from '../../Redux/order/orderActions';
import { withTranslation } from 'react-i18next';
import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { checkTokenValidity } from '../../Redux/auth/authActions';
import { REACT_APP_baseUrl } from 'utils/app-constants';
import { setGoalItem } from 'Redux/goalItems/goalItemsActions';
import GoalItemStatusModal from 'components/GoalItems/GoalItemStatusModal';
import GoalItemConfirmModal from 'components/GoalItems/GoalItemConfirmModal';
import goalItemImage from '../../assests/img/goalitems/icon-change-goal-item.svg'

class EMagzinePage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            confirmOrder:false,
            orders:{},
            cardPayment:false,
            goalModalShow:false,
            goalStatusModalShow:false,
            goalModalDetail:'',
            emagazineDetails:{},
            loading:true
         }
    }

    componentDidMount=()=>{
        localStorage.removeItem("type");
        localStorage.setItem("type", "eMagazines");
        const payload={
            "brands": {
                "id": this.props.match.params.id,
                "currency": this.props.countrySelected
            }
        }
        this.fetchEmagazineDetails(payload)
        this.props.removeOrder()
        this.fetchTermsConds() 
        this.props.checkTokenValidity() 
    }
    componentDidUpdate=(prevProps,prevState)=>{
       
        if((prevProps.currancyCode !== this.props.currancyCode) || (this.props.match.params.id !== prevProps.match.params.id) ){
            const payload={
                "brands": {
                    "id": this.props.match.params.id,
                    "currency":this.props.countrySelected
                }
            }
            this.fetchEmagazineDetails(payload)
            this.fetchTermsConds(this.props.match.params?.id)
            
        }
        if(!this.state.loading && this.state.emagazineDetails && Object.keys(this.state.emagazineDetails).length === 0) {
            this.props.history.replace({
                pathname:"/404", 
                redirectionURL:'/e-magazines'
            })
        }
    }
    fetchTermsConds = (payload) => {
        payload = {
              "brands": {
                  "id":this.props.match.params.id,
                  "currency": this.props.countrySelected
              }
          }
          return (
          
              axios.post(`${REACT_APP_baseUrl}/brands/terms`,
                 payload,
                  {
                      headers: {
                          'Content-Type': 'application/json',
                          'Cache-Control': 'no-cache, no-store, must-revalidate',
                          'Expires': '0'
                      },
                  })
                  .then(response  => {
                      // const termsConds = response.data.data.terms
                      this.setState({
                          termsConds: response.data.data?.terms
                            
                      })

                     
                  })
          )
      }
    orderSummary=(val,...props)=>{
        let amount = this.state.emagazineDetails?.rates?.[0]
        let amount_points = currancyToPoints(amount)
        let data = props[0]?.data || null
        let type = props[0]?.type || null
        let paymentType = props[0]?.paymentType
        paymentType === 'creditcard' ? this.setState({cardPayment:true}) : this.setState({cardPayment:false})
        this.setState(prevState=>({
            confirmOrder:val,
            orders: {
                "brand_id":this.state.emagazineDetails.id,
                "quantity":1,
                "giftcard_value":this.state.emagazineDetails?.rates?.[0],
                "amount":amount,
                "program_id":1,
                "isforself":!props[0]?.isGift,
                "use_hassad_points":paymentType === 'creditcard' ? false : this.props.pointBalance>0,
                "language_code":this.props.lang,
                "currency":this.props.countrySelected,
                "hassad_points": paymentType === 'creditcard' ? 0 : Number(amount_points) <= Number(this.props.pointBalance) ? Number(amount_points): Number(this.props.pointBalance),
                "gift_message": props[0]?.message,
            }
        }),() => type === 'email' ?
        this.setState(prevState => ({
            orders: {
              ...prevState.orders,
              'contact_email':data
            }
          })) :
        this.setState(prevState => ({
            orders: {
              ...prevState.orders,
              'contact_phone':data
            }
          }))
        )
    }
    setGoalModalView = (value)=>{
        this.setState({goalModalShow: !this.state.goalModalShow,goalModalDetail:value || this.state.goalModalDetail})
    }
    setGoalConfirmModalView = ()=>{
        this.setState({goalConfirmModalShow: !this.state.goalConfirmModalShow})
    }

    setGoalStatusModalView = () => {
        this.setState({goalStatusModalShow:false})
    }

    setGoalItem = (date) => {
        let payload = {
            "customer_goal_item": [{
                "product_id": this.state.emagazineDetails?.id,
                "product_type": "EMAGAZINE",
                "product_price": this.state.emagazineDetails?.rates?.[0],
                "goal_item": this.state.goalModalDetail === 'Remove' ? false : true,
                "remind_me_later_date": date ? new Date(date)?.toISOString()?.slice(0,10) : ''
            }]
        }

        let updatePayload = {
            "customer_goal_items": {
                "product_id": this.state.emagazineDetails?.id,
                "product_type": "EMAGAZINE",
                "product_price": this.state.emagazineDetails?.rates?.[0],
                "goal_item": this.state.goalModalDetail === 'Remove' ? false : true,
                "remind_me_later_date": date ? new Date(date)?.toISOString()?.slice(0,10) : ''
            }
        }

        this.props.setGoalItem(
            this.state.goalModalDetail === 'Update' ? updatePayload : payload,
            ()=>this.fetchBrandHandler(this.props.match?.params?.id),
            this.state.goalModalDetail);
        this.setState({goalStatusModalShow:true})
    }

    fetchEmagazineDetails = (payload) =>{
        axios
          .post(
            `${REACT_APP_baseUrl}/brands/get_emagazine_brand_details`,
            payload,
          )
          .then((response) => {
            if (response.data.code === 200) {
              const brand = response?.data?.data?.brand;
              this.setState({
                emagazineDetails: brand,
                loading: false,
              });
            } else {
              this.setState({ loading: false });
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            const errorMsg = error;
            console.log(errorMsg);
          });   
    }

    render() { 
        const goalItemStyle = {
            backgroundImage: `url(${ goalItemImage })` ,
             backgroundRepeat : 'no-repeat',
                 backgroundPosition: '10%' , 
          };
        const{t,currancyCode}=this.props
        const breadcrumbs =[
            {name:t("Home"),url:"/",active:true},
            {name:t("E-Magazines"),url:`/e-magazines`,active:true},
            {name:this.props.lang!=='ar'?this.state.emagazineDetails.name: this.state.emagazineDetails.name_arabic,url:'',active:false}
        ]
        const {confirmOrder,orders, goalModalDetail} = this.state;
        const eMagazine = this.state.emagazineDetails; 
        return ( 
            <>
            <GoalItemStatusModal goalModalDetail={goalModalDetail} show={this.state.goalStatusModalShow} setGoalModalView={this.setGoalStatusModalView} />
            { this.state.loading &&  this.state.loading ? 
                <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </section> :
                <section>
                    <Breadcrumbs crumbs={breadcrumbs}/>
                    <GoalItemConfirmModal goalModalDetail={goalModalDetail} setGoalModalView={this.setGoalModalView} show={this.state.goalModalShow} lang={this.props.lang} productValue={eMagazine.rates} currancyCode={this.props.currancyCode} setGoalItem={this.setGoalItem} image={eMagazine?.images?.color?.medium_rectangle}
                        title={eMagazine?.name} title_arabic={eMagazine?.name_arabic}
                        />
                        <div className="px-md-5 px-3 pt-md-5 pt-3 d-flex flex-md-row flex-column">
                            {
                                confirmOrder && <OrderConfirmationModal cardPayment={this.state.cardPayment} lang={this.props.lang} orders={orders} card={eMagazine} show={confirmOrder} remove={this.orderSummary}/>
                            }
                        <div className="col-3 p-0 d-md-block d-none">
                            <img  
                                className="img-fluid mx-auto d-block w-100"   
                                src={eMagazine.images?.color.medium_rectangle}
                                alt={eMagazine.name}
                            />
                        </div>
                        <div className="flex-grow-1 px-md-5 px-0">
                            <div className="pb-md-3 pb-2">
                                <div className="fs-md-3 fs-5 fw-md-bold fw-bolder product_title">
                                { this.props.lang !== 'ar' ?eMagazine.name:eMagazine.name_arabic }
                                </div>
                            </div>
                            { eMagazine.goal_item_flag && <div style={goalItemStyle} className='goalItemSVG text-light text-center p-1 mb-2'><p className='ml-3'>{t("Goal Item")}</p></div>}
                            <div className="fs-5 fw-bold text-dark" style={{fontSize:"20px"}}>
                            {eMagazine.rates} <small>{t(currancyCode)}</small>
                            </div>
                            <div className="pb-3 text-muted m-0 py-2">
                            {t("Edition")}:{ this.props.lang !== 'ar' ?eMagazine.edition:eMagazine.edition_arabic }
                            </div>
                            <div className='d-md-none d-block p-3'>
                                <img 
                                    className="mx-auto d-block"
                                    src={eMagazine.images?.color.medium_rectangle} 
                                    alt={eMagazine.name} srcSet=""
                                    style={{objectFit:'cover',height:'60vw',maxWidth:'100%'}}/>
                            </div>
                            
                            <hr className="hr-line m-0 my-2"/>                            
                            <div className="p-0">
                            <div className="product_title m-0 pt-3 pb-1 fs-5">{t("Highlights")}</div>
                                <p className="text-muted p-0">{ this.props.lang !== 'ar' ?eMagazine.short_description:eMagazine.short_description_arabic }</p>
                                <div className=" p-0 text-secondary fs-6">
                                {/* <div >{t("Country")}:   {eMagazine.country_name}</div>
                                    <div >{t("Language")}: {this.props.lang==='en'?eMagazine.language: eMagazine.language_arabic}</div>
                                    <div >{t("Publisher")}: {this.props.lang==='en'?eMagazine.publisher : eMagazine.publisher_arabic}</div>
                                    <div >{t("Frequency")}: {this.props.lang==='en'?eMagazine.frequency: eMagazine.frequency_arabic}</div> */}
                                </div> 
                                <table class="borderless fw-bold">
									<tbody>
											<tr><td class="d-flex justify-content-start flip" >{t("Country")}:</td><td class="no-padding">{this.props.lang!=='ar'? eMagazine.country_name :eMagazine.country_name_arabic}</td></tr>
											<tr><td class="d-flex justify-content-start flip" >{t("Language")}:</td><td class="no-padding ">{this.props.lang!=='ar'?eMagazine.language: eMagazine.language_arabic}</td></tr>
											<tr><td class="d-flex justify-content-start flip" >{t("Publisher")}:</td><td class="no-padding">{this.props.lang!=='ar'?eMagazine.publisher : eMagazine.publisher_arabic}</td></tr>
											<tr><td class="d-flex justify-content-start flip" >{t("Frequency")}:</td><td class="no-padding">{this.props.lang!=='ar'?eMagazine.frequency: eMagazine.frequency_arabic}</td></tr>
									</tbody>
								</table>
                            </div>
                            <div className='d-block d-md-none my-4 px-2'>
                            <TAndConly termsConds={this.state.termsConds} id={this.props.match.params.id} lang={this.props.lang}/>
                            </div>
                            <hr className="hr-line m-0 my-2"/> 
                        </div>
                        <BuyNowCard quantityDisable={true} onSubmit={this.orderSummary}  lang={this.props.lang} 
                        price={eMagazine.rates > 1 ? true : false}
                        setGoalModalView={this.setGoalModalView}
                            selectedRate = {eMagazine.rates}
                            productID = {this.props.match?.params?.id}
                            GoalItem={true}
                            toogleLang={this.props.toogleLang}
                        />
                            
                    </div>
                    <div className='d-md-block d-none my-4 px-5 col-9'>
                    <TAndConly termsConds={this.state.termsConds} id={this.props.match.params.id} lang={this.props.lang}/>
                            </div>
                </section>
            }
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        pointBalance:state.point.pointBalance,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code
    }
}


const mapDispatchToProps = dispatch => {
    return {
        removeOrder : () => dispatch(removeOrder()),
        checkTokenValidity : () => dispatch(checkTokenValidity()),
        setGoalItem: (payload,productAPI,status) => dispatch(setGoalItem(payload,productAPI,status)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (withTranslation()(EMagzinePage));