import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS, SET_COUNTRY, SET_CONVERSION_RATE, SET_LANGUAGES } from "./countryTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';
import store from "Redux/store";

export const fetchCountriesRequest = () => {
    return {
        type: FETCH_COUNTRIES_REQUEST,
    }
}

export const fetchCountriesSuccess = data => {
    return {
        type: FETCH_COUNTRIES_SUCCESS,
        payload: data
    }
}

export const fetchCountriesFailure = error => {
    return {
        type: FETCH_COUNTRIES_FAILURE,
        payload: error
    }
}

export const setCountry = data => {
    return {
        type: SET_COUNTRY,
        payload: data
    }
}
export const setCountryHandler = id => {
    return (dispatch) => {
        localStorage.setItem("countryID", id);
        dispatch(setCountry(id))
        dispatch(fetchConversionRate(id))
    }
}
export const setLanguagesHandler = (payload) => {
    return {
        type: SET_LANGUAGES,
        payload: payload
    }
}
export const setConversionRate = data => {
    return {
        type: SET_CONVERSION_RATE,
        payload: data
    }
}
export const fetchConversionRate = (id) => {
    const payload = {
        "giftcard_units": {
            "currency": id
        }
    }
    return (dispatch) => {
        axios.post(`${REACT_APP_baseUrl}/giftcard_units/get_conversion_rate`,
            payload, {
            params: {
            }
        })
            .then(response => {
                let conversionRate = response.data.data;
                dispatch(setConversionRate(conversionRate))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
            })
    }
}

const cacheHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Expires': '0'
        }
    }
}

export const fetchCountries = () => {
    let userCountry = localStorage?.getItem("countryID") || 1
    return (dispatch) => {
        dispatch(fetchCountriesRequest);
        axios.get(`${REACT_APP_baseUrl}/countries/list`,
            cacheHeaders())
            .then(response => {
                let countries = response?.data?.data?.countries;
                countries.sort(function (a, b) {
                    if (a?.name < b?.name) {
                        return -1;
                    }
                    if (a?.name > b?.name) {
                        return 1;
                    }
                    return 0;
                });
                let country = countries?.filter(ite => ite?.currencies?.[0]?.id == userCountry)
                let selectedCountry = {
                    countryName: country?.[0]?.name,
                    countryId: country?.[0]?.id,
                    currencyId: country?.[0]?.currencies?.[0]?.id,
                    countryCode: country?.[0]?.code,
                    currencyCode: country?.[0]?.currencies?.[0]?.code,
                };
                let payload = {
                    countries: countries,
                    languages: country?.[0]?.languages,
                    selectedCountry: selectedCountry
                }
                dispatch(fetchCountriesSuccess(payload))
                localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchCountriesFailure("failed"))
            })
    }
}

export const fetchCountryID = (toogleLang) => {
    let localCountryID = localStorage.getItem("countryID");
    let userCountry = localStorage.getItem("selectedCountry")
    let lang = localStorage.getItem("lang")
    if(localCountryID === null || userCountry === null){
        let DefaultCountry = {
            countryName: "United Arab Emirates",
            countryId:6,
            currencyId: 1,
            countryCode: "ARE",
            currencyCode: "AED"
        };
        localStorage.setItem("selectedCountry", JSON.stringify(DefaultCountry))
        localStorage.setItem("countryID","1")
        lang="en"
    }else if(userCountry) userCountry = JSON.parse(userCountry)
    return async (dispatch) => {
        let localToken = localStorage.getItem("userToken")
        let payload = {
            "session": 
                {
                "user_token":localToken
                }
        }
        if(localToken){
            localToken && await axios.post(`${REACT_APP_baseUrl}/accounts/sessions/get_details_by_token`,payload)
            .then((response)=>{
                if(response.data.code === 200){
                    const userDetails = response.data.data?.user
                    let countryID = localCountryID ? localCountryID : userDetails?.currency_id
                    console.log(countryID,"countryID")
                    let selectedCountry = {
                        countryName: userCountry?.countryName || userDetails?.country_name,
                        countryId: userCountry?.countryId || userDetails?.country_id,
                        currencyId: userCountry?.currencyId || userDetails?.currency_id,
                        countryCode: userCountry?.countryCode || userDetails?.currency_code,
                        currencyCode: userCountry?.currencyCode || userDetails?.country_code,
                    };
                    let Languages
                    if(store.getState()?.countries?.countries?.length > 0){
                        Languages = store.getState()?.countries?.countries?.filter(ite => ite?.currencies?.[0]?.id == countryID)
                    }
                    let payload = {
                        selectedCountry,
                        languages: Languages?.[0]?.languages,
                    }
                    if(typeof toogleLang === "function"){
                        const userLang = lang ? lang : userDetails?.language_code
                        toogleLang(userLang)
                    }
                    countryID && localStorage.setItem("countryID", countryID);
                    localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry))
                    countryID && dispatch(setCountry(countryID))
                    dispatch(setLanguagesHandler(payload))
                }
                else if(response.data.code === 400 && localCountryID){
                    dispatch(setCountry(localCountryID))  
                }
                else{
                    dispatch(setCountry(1))  
                }
            }).catch(err => {
                dispatch(setCountry(1))
            })
        }else if(typeof toogleLang === "function"){
            const userLang = lang
            toogleLang(userLang)
        }
    }
}